import axios from 'axios';
import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Popup, Checkbox, Dropdown, TextField} from '@partssourceinc/react-ui-core';
import {timeZones,ianaTime} from 'data/TimeZonesData';
import moment from 'moment-timezone';
import {getFormattedPhoneNum, stripAwayPhoneNumberSymbols, stripAwayPhoneNumberCode, logEvent} from 'utility';

import {validateStrippedPhoneNumber} from '/validation';

const View = {
    TrackOrder: 'TrackOrder',
    VerifyYourNumber: 'VerifyYourNumber',
    NumberVerified: 'NumberVerified', 
};

const TrackOrderSMSDialog = ({orderId, onClose, onCloseConfirm, screenOrigin}) => {
    const [isReceiveChecked, setIsReceiveChecked] = useState(false);
    const [view, setView] = useState(View.TrackOrder);
    const [selectedTimeZone, setSelectedTimeZone] = useState(null);
    const [mobileNumberToOrder, setMobileNumberToOrder] = useState(null);
    const [mobileState, setMobileState] = useState(null);
    const [invalidPhone, setInvalidPhone] = useState(false);
    const [invalidCode, setInvalidCode] = useState(false);
    
    const {user: {settings: {notificationSettings: {mobileNumber, isMobileVerified, timeZoneId}}, info: {contactId}}} = useSelector((state) => ({
        user: state.user,
    }));

    let selectedTimeZoneVal = (selectedTimeZone) ? selectedTimeZone.zoneId : '';
    let timeZoneNotSelected = selectedTimeZoneVal === '' ? true : false;
    
    let formattedPhoneNumber = (!(_.isNil(mobileNumberToOrder)) && mobileNumberToOrder !== '') ? getFormattedPhoneNum(stripAwayPhoneNumberCode(mobileNumberToOrder)) : null;
    let noPhoneNumberEntered = !formattedPhoneNumber || formattedPhoneNumber.length <= 3; // the formattedPhoneNumber is set to "1 (" when empty
    let disableConfirm = !isReceiveChecked || invalidPhone || noPhoneNumberEntered || timeZoneNotSelected;
    
    useEffect(() => {
        // set the user's mobile number to the Order's mobile input
        if (mobileNumber) {
            setMobileNumberToOrder(mobileNumber);
        }

        if (timeZoneNotSelected) {
            // set Windows time zone (if any)
            const ianaZone = moment.tz.guess();
            const ianaZoneName = ianaTime.find(IanaTime => IanaTime.type === ianaZone);
            const windowsTime = ianaZoneName ? timeZones.find(TimeZones => TimeZones.zoneId === ianaZoneName.mapZone) : '';
        
            if (windowsTime && windowsTime.zoneId) {
                setSelectedTimeZone(timeZones.filter(k => k.zoneId === windowsTime.zoneId)[0]);
                timeZoneNotSelected = false;
            }
        }

        if (timeZoneId) {
            setSelectedTimeZone(timeZones.filter(k => k.zoneId === timeZoneId)[0]);
        }
    }, []);
    
    function saveOLN() {
        let formatted = (mobileNumberToOrder) ? ('+' + mobileNumberToOrder).replace(/\D/g, '') : '';
        
        const oln = {
            MobileNumber: formatted,
            TimeZoneId: (selectedTimeZone) ? selectedTimeZone.zoneId : '',
            OrderId: orderId,
        };
        
        axios.post('/ShoppingService/api/v1/notification/saveOLN', oln);
    }
    
    function sendVCode() {
        if (mobileNumberToOrder === null) return false;

        axios.post('/ShoppingService/api/v1/notification/verify/' + mobileNumberToOrder).then(response => {
            if (response.data.error === 'Verified') {
                saveOLN();
                setMobileState('verified');
                setView(View.NumberVerified);

                logEvent('ORDER TEXT OPT-IN', {
                    'User ID': contactId,
                    'Origin': screenOrigin,
                    'Order Number/Id': orderId,
                });
            } else if (response.data.success || response.data) {
                setMobileState('verify');
                setView(View.VerifyYourNumber);
            } else {
                setInvalidPhone(true);
            }
        });
    }
    function confirmVCode() {
        const codeEnt = $('#verificationCode').val();
        if (!codeEnt || !codeEnt.match(/^[0-9]{4}$/)) {
            setMobileState('verify');
            setView(View.VerifyYourNumber);
            setInvalidCode(true);
        }
        axios.post(`/ShoppingService/api/v1/notification/confirm/${mobileNumberToOrder}/${codeEnt}`)
            .then((r) => {
                if (r.data.success) {
                    saveOLN();    
                    setMobileState('verified');
                    setView(View.NumberVerified);
                } else {
                    setMobileState('verify');
                    setView(View.VerifyYourNumber);
                    setInvalidCode(true);
                }
            });
    }
    const validatePhoneNumber = (e) => {
        let number = e && e.target && e.target.value;
        number = stripAwayPhoneNumberSymbols(number);

        if (!number) {
            setInvalidPhone(false);
            setMobileNumberToOrder(null);
            // TODO: set isMobileVerified to false
            
            return;
        }

        let numberStripped = stripAwayPhoneNumberSymbols(number);
        let numberWithoutCode = stripAwayPhoneNumberCode(numberStripped);

        if (!numberWithoutCode) {
            // the number is incomplete/wrong
            setInvalidPhone(true);
            setMobileNumberToOrder(numberStripped);
            
            // TODO: set isMobileVerified to false

            return;
        }

        let isValid = validateStrippedPhoneNumber(numberWithoutCode);

        if (isValid) {
            if (mobileNumber !== numberStripped) {
                // the number is valid, but needs to be verified
                setInvalidPhone(false);
                setMobileNumberToOrder(numberStripped);

                // TODO: set isMobileVerified to false

                return;
            }
        } else {
            // the number is invalid
            setInvalidPhone(true);
            setMobileState('edit');
            setMobileNumberToOrder(numberStripped);

            return;
        }
    }

    const handleChangeTimeZone = (ev) => {
        setSelectedTimeZone(timeZones.find(t => t.zoneId === ev.target.value));
    }

    return (
        <React.Fragment>
            {view === View.TrackOrder ? 
                <Popup show={true} confirmText="Continue"
                    cancelText="Cancel" 
                    hideButtons={false} 
                    onCancel={onClose}
                    disableConfirm={disableConfirm}
                    onConfirm={sendVCode}>
                    <div>
                        <h1 style={{marginBottom: '24px'}}>Track Order (SMS)</h1>
                        <Checkbox
                            label={'Receive mobile notifications for this order'} 
                            checked={isReceiveChecked}
                            onChange={() => setIsReceiveChecked(!isReceiveChecked)}
                        />
                        <TextField 
                            id="mobileNumber"
                            onChange={(e) => validatePhoneNumber(e)}
                            label="Mobile Number" 
                            placeholder="Mobile Phone" 
                            tabIndex={0} 
                            showErrorMessage={invalidPhone || noPhoneNumberEntered}
                            text={formattedPhoneNumber} />
                        <Dropdown label="Time Zone" 
                            onChange={(e) => handleChangeTimeZone(e)} 
                            options={timeZones} 
                            selectedValue={selectedTimeZoneVal} 
                            suppressBlankOption={false} 
                            valueField="zoneId"
                            showErrorMessage={timeZoneNotSelected} 
                            textField="zoneName" />
                        <div style={{marginBottom: '50px', marginTop: '16px'}}>
                            By checking this box, you consent to receive text messages from PartsSource at this number about your order. 
                            Text message notifications are typically sent between 8am and 9pm. Message and data rates may apply.
                        </div>
                    </div>
                </Popup> : null}
            {view === View.VerifyYourNumber ? 
                <Popup show={true} confirmText="Continue"
                    cancelText="Cancel" 
                    hideButtons={false} 
                    onCancel={onClose}
                    className="verify-your-number-sms-popup"
                    onConfirm={confirmVCode}>
                    <div>
                        <h1>Verify Your Number</h1>
                        <div style={{fontWeight: 'bold'}}>Enter your four-digit SMS verification code below.</div>
                        <TextField id="verificationCode" label="Verification Code" placeholder="Verification Code"
                            tabIndex={0} showErrorMessage={invalidCode} /> 
                        <div onClick={sendVCode} style={{color: '#7e7e7e', textDecoration: 'underline', cursor: 'pointer', marginTop: '10px'}}>Didn't get it? Send a new code</div> 
                    </div>
                </Popup> : null}
            {view === View.NumberVerified ? 
                <Popup show={true}
                    className="number-verified-sms-popup" 
                    hideButtons={true}
                    onCancel={onCloseConfirm}
                    onConfirm={onCloseConfirm}>
                    <div>
                        <h1>Number Verified. Thank you.</h1>
                        <div style={{marginTop: '24px', marginBottom: '50px'}}>
                            <div style={{fontWeight: 'bold'}}>Did you know…</div>
                            <div>You can activate and manage your texts and emails for all orders in your notifications settings. 
                                <span> <a href="/notifications" target="_blank" rel="noopener noreferrer" style={{color: '#7e7e7e', textDecoration: 'underline'}}>Go to Notification Settings</a>.</span></div>
                        </div>
                    </div>
                </Popup> : null}
        </React.Fragment>
    );    
}

export default TrackOrderSMSDialog;
