export const timeZones = [
    // Note: we want the US time zones to be listed at the top of the list
    {zoneId: 'Atlantic Standard Time', zoneName: '(UTC-04:00) Atlantic Time (Canada)'},
    {zoneId: 'Eastern Standard Time', zoneName: '(UTC-05:00) Eastern Time (US and Canada)'},
    {zoneId: 'Central Standard Time', zoneName: '(UTC-06:00) Central Time (US and Canada)'},
    {zoneId: 'Mountain Standard Time', zoneName: '(UTC-07:00) Mountain Time (US and Canada)'},
    {zoneId: 'Pacific Standard Time', zoneName: '(UTC-08:00) Pacific Time (US and Canada)'},
    {zoneId: 'Alaskan Standard Time', zoneName: '(UTC-09:00) Alaska'},
    {zoneId: 'Hawaiian Standard Time', zoneName: '(UTC-10:00) Hawaii'},
    {zoneId: 'Samoa Standard Time', zoneName: '(UTC+13:00) Samoa'},
    {zoneId: 'Dateline Standard Time', zoneName: '(UTC-12:00) International Date Line West'},
    {zoneId: 'UTC-11', zoneName: '(UTC-11:00) Coordinated Universal Time-11'},
    {zoneId: 'Aleutian Standard Time', zoneName: '(UTC-10:00) Aleutian Islands'},
    {zoneId: 'Marquesas Standard Time', zoneName: '(UTC-09:30) Marquesas Islands'},
    {zoneId: 'UTC-09', zoneName: '(UTC-09:00) Coordinated Universal Time-09'},
    {zoneId: 'Pacific Standard Time (Mexico)', zoneName: '(UTC-08:00) Baja California'},
    {zoneId: 'UTC-08', zoneName: '(UTC-08:00) Coordinated Universal Time-08'},
    {zoneId: 'US Mountain Standard Time', zoneName: '(UTC-07:00) Arizona'},
    {zoneId: 'Mountain Standard Time (Mexico)', zoneName: '(UTC-07:00) Chihuahua, La Paz, Mazatlan'},
    {zoneId: 'Central America Standard Time', zoneName: '(UTC-06:00) Central America'},
    {zoneId: 'Easter Island Standard Time', zoneName: '(UTC-06:00) Easter Island'},
    {zoneId: 'Central Standard Time (Mexico)', zoneName: '(UTC-06:00) Guadalajara, Mexico City, Monterrey'},
    {zoneId: 'Canada Central Standard Time', zoneName: '(UTC-06:00) Saskatchewan'},
    {zoneId: 'SA Pacific Standard Time', zoneName: '(UTC-05:00) Bogota, Lima, Quito, Rio Branco'},
    {zoneId: 'Eastern Standard Time (Mexico)', zoneName: '(UTC-05:00) Chetumal'},
    {zoneId: 'Haiti Standard Time', zoneName: '(UTC-05:00) Haiti'},
    {zoneId: 'Cuba Standard Time', zoneName: '(UTC-05:00) Havana'},
    {zoneId: 'US Eastern Standard Time', zoneName: '(UTC-05:00) Indiana (East)'},
    {zoneId: 'Turks And Caicos Standard Time', zoneName: '(UTC-05:00) Turks and Caicos'},
    {zoneId: 'Paraguay Standard Time', zoneName: '(UTC-04:00) Asuncion'},
    {zoneId: 'Venezuela Standard Time', zoneName: '(UTC-04:00) Caracas'},
    {zoneId: 'Central Brazilian Standard Time', zoneName: '(UTC-04:00) Cuiaba'},
    {zoneId: 'SA Western Standard Time', zoneName: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan'},
    {zoneId: 'Pacific SA Standard Time', zoneName: '(UTC-04:00) Santiago'},
    {zoneId: 'Newfoundland Standard Time', zoneName: '(UTC-03:30) Newfoundland'},
    {zoneId: 'Tocantins Standard Time', zoneName: '(UTC-03:00) Araguaina'},
    {zoneId: 'E. South America Standard Time', zoneName: '(UTC-03:00) Brasilia'},
    {zoneId: 'SA Eastern Standard Time', zoneName: '(UTC-03:00) Cayenne, Fortaleza'},
    {zoneId: 'Argentina Standard Time', zoneName: '(UTC-03:00) City of Buenos Aires'},
    {zoneId: 'Greenland Standard Time', zoneName: '(UTC-03:00) Greenland'},
    {zoneId: 'Montevideo Standard Time', zoneName: '(UTC-03:00) Montevideo'},
    {zoneId: 'Magallanes Standard Time', zoneName: '(UTC-03:00) Punta Arenas'},
    {zoneId: 'Saint Pierre Standard Time', zoneName: '(UTC-03:00) Saint Pierre and Miquelon'},
    {zoneId: 'Bahia Standard Time', zoneName: '(UTC-03:00) Salvador'},
    {zoneId: 'UTC-02', zoneName: '(UTC-02:00) Coordinated Universal Time-02'},
    {zoneId: 'Mid-Atlantic Standard Time', zoneName: '(UTC-02:00) Mid-Atlantic - Old'},
    {zoneId: 'Azores Standard Time', zoneName: '(UTC-01:00) Azores'},
    {zoneId: 'Cape Verde Standard Time', zoneName: '(UTC-01:00) Cabo Verde Is.'},
    {zoneId: 'UTC', zoneName: '(UTC) Coordinated Universal Time'},
    {zoneId: 'GMT Standard Time', zoneName: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London'},
    {zoneId: 'Greenwich Standard Time', zoneName: '(UTC+00:00) Monrovia, Reykjavik'},
    {zoneId: 'W. Europe Standard Time', zoneName: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna'},
    {zoneId: 'Central Europe Standard Time', zoneName: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague'},
    {zoneId: 'Romance Standard Time', zoneName: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris'},
    {zoneId: 'Morocco Standard Time', zoneName: '(UTC+01:00) Casablanca'},
    {zoneId: 'Sao Tome Standard Time', zoneName: '(UTC+01:00) Sao Tome'},
    {zoneId: 'Central European Standard Time', zoneName: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb'},
    {zoneId: 'W. Central Africa Standard Time', zoneName: '(UTC+01:00) West Central Africa'},
    {zoneId: 'Jordan Standard Time', zoneName: '(UTC+02:00) Amman'}, {zoneId: 'GTB Standard Time', zoneName: '(UTC+02:00) Athens, Bucharest'},
    {zoneId: 'Middle East Standard Time', zoneName: '(UTC+02:00) Beirut'},
    {zoneId: 'Egypt Standard Time', zoneName: '(UTC+02:00) Cairo'},
    {zoneId: 'E. Europe Standard Time', zoneName: '(UTC+02:00) Chisinau'},
    {zoneId: 'Syria Standard Time', zoneName: '(UTC+02:00) Damascus'},
    {zoneId: 'West Bank Standard Time', zoneName: '(UTC+02:00) Gaza, Hebron'},
    {zoneId: 'South Africa Standard Time', zoneName: '(UTC+02:00) Harare, Pretoria'},
    {zoneId: 'FLE Standard Time', zoneName: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius'},
    {zoneId: 'Israel Standard Time', zoneName: '(UTC+02:00) Jerusalem'},
    {zoneId: 'Kaliningrad Standard Time', zoneName: '(UTC+02:00) Kaliningrad'},
    {zoneId: 'Sudan Standard Time', zoneName: '(UTC+02:00) Khartoum'},
    {zoneId: 'Libya Standard Time', zoneName: '(UTC+02:00) Tripoli'},
    {zoneId: 'Namibia Standard Time', zoneName: '(UTC+02:00) Windhoek'},
    {zoneId: 'Arabic Standard Time', zoneName: '(UTC+03:00) Baghdad'},
    {zoneId: 'Turkey Standard Time', zoneName: '(UTC+03:00) Istanbul'},
    {zoneId: 'Arab Standard Time', zoneName: '(UTC+03:00) Kuwait, Riyadh'},
    {zoneId: 'Belarus Standard Time', zoneName: '(UTC+03:00) Minsk'},
    {zoneId: 'Russian Standard Time', zoneName: '(UTC+03:00) Moscow, St. Petersburg'},
    {zoneId: 'E. Africa Standard Time', zoneName: '(UTC+03:00) Nairobi'},
    {zoneId: 'Iran Standard Time', zoneName: '(UTC+03:30) Tehran'},
    {zoneId: 'Arabian Standard Time', zoneName: '(UTC+04:00) Abu Dhabi, Muscat'},
    {zoneId: 'Astrakhan Standard Time', zoneName: '(UTC+04:00) Astrakhan, Ulyanovsk'},
    {zoneId: 'Azerbaijan Standard Time', zoneName: '(UTC+04:00) Baku'},
    {zoneId: 'Russia Time Zone 3', zoneName: '(UTC+04:00) Izhevsk, Samara'},
    {zoneId: 'Mauritius Standard Time', zoneName: '(UTC+04:00) Port Louis'},
    {zoneId: 'Saratov Standard Time', zoneName: '(UTC+04:00) Saratov'},
    {zoneId: 'Georgian Standard Time', zoneName: '(UTC+04:00) Tbilisi'},
    {zoneId: 'Volgograd Standard Time', zoneName: '(UTC+04:00) Volgograd'},
    {zoneId: 'Caucasus Standard Time', zoneName: '(UTC+04:00) Yerevan'},
    {zoneId: 'Afghanistan Standard Time', zoneName: '(UTC+04:30) Kabul'},
    {zoneId: 'West Asia Standard Time', zoneName: '(UTC+05:00) Ashgabat, Tashkent'},
    {zoneId: 'Ekaterinburg Standard Time', zoneName: '(UTC+05:00) Ekaterinburg'},
    {zoneId: 'Pakistan Standard Time', zoneName: '(UTC+05:00) Islamabad, Karachi'},
    {zoneId: 'India Standard Time', zoneName: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi'},
    {zoneId: 'Sri Lanka Standard Time', zoneName: '(UTC+05:30) Sri Jayawardenepura'},
    {zoneId: 'Nepal Standard Time', zoneName: '(UTC+05:45) Kathmandu'},
    {zoneId: 'Central Asia Standard Time', zoneName: '(UTC+06:00) Astana'},
    {zoneId: 'Bangladesh Standard Time', zoneName: '(UTC+06:00) Dhaka'},
    {zoneId: 'Omsk Standard Time', zoneName: '(UTC+06:00) Omsk'},
    {zoneId: 'Myanmar Standard Time', zoneName: '(UTC+06:30) Yangon (Rangoon)'},
    {zoneId: 'SE Asia Standard Time', zoneName: '(UTC+07:00) Bangkok, Hanoi, Jakarta'},
    {zoneId: 'Altai Standard Time', zoneName: '(UTC+07:00) Barnaul, Gorno-Altaysk'},
    {zoneId: 'W. Mongolia Standard Time', zoneName: '(UTC+07:00) Hovd'},
    {zoneId: 'North Asia Standard Time', zoneName: '(UTC+07:00) Krasnoyarsk'},
    {zoneId: 'N. Central Asia Standard Time', zoneName: '(UTC+07:00) Novosibirsk'},
    {zoneId: 'Tomsk Standard Time', zoneName: '(UTC+07:00) Tomsk'},
    {zoneId: 'China Standard Time', zoneName: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi'},
    {zoneId: 'North Asia East Standard Time', zoneName: '(UTC+08:00) Irkutsk'},
    {zoneId: 'Singapore Standard Time', zoneName: '(UTC+08:00) Kuala Lumpur, Singapore'},
    {zoneId: 'W. Australia Standard Time', zoneName: '(UTC+08:00) Perth'},
    {zoneId: 'Taipei Standard Time', zoneName: '(UTC+08:00) Taipei'},
    {zoneId: 'Ulaanbaatar Standard Time', zoneName: '(UTC+08:00) Ulaanbaatar'},
    {zoneId: 'Aus Central W. Standard Time', zoneName: '(UTC+08:45) Eucla'},
    {zoneId: 'Transbaikal Standard Time', zoneName: '(UTC+09:00) Chita'},
    {zoneId: 'Tokyo Standard Time', zoneName: '(UTC+09:00) Osaka, Sapporo, Tokyo'},
    {zoneId: 'North Korea Standard Time', zoneName: '(UTC+09:00) Pyongyang'},
    {zoneId: 'Korea Standard Time', zoneName: '(UTC+09:00) Seoul'},
    {zoneId: 'Yakutsk Standard Time', zoneName: '(UTC+09:00) Yakutsk'},
    {zoneId: 'Cen. Australia Standard Time', zoneName: '(UTC+09:30) Adelaide'},
    {zoneId: 'AUS Central Standard Time', zoneName: '(UTC+09:30) Darwin'},
    {zoneId: 'E. Australia Standard Time', zoneName: '(UTC+10:00) Brisbane'},
    {zoneId: 'AUS Eastern Standard Time', zoneName: '(UTC+10:00) Canberra, Melbourne, Sydney'},
    {zoneId: 'West Pacific Standard Time', zoneName: '(UTC+10:00) Guam, Port Moresby'},
    {zoneId: 'Tasmania Standard Time', zoneName: '(UTC+10:00) Hobart'},
    {zoneId: 'Vladivostok Standard Time', zoneName: '(UTC+10:00) Vladivostok'},
    {zoneId: 'Lord Howe Standard Time', zoneName: '(UTC+10:30) Lord Howe Island'},
    {zoneId: 'Bougainville Standard Time', zoneName: '(UTC+11:00) Bougainville Island'},
    {zoneId: 'Russia Time Zone 10', zoneName: '(UTC+11:00) Chokurdakh'},
    {zoneId: 'Magadan Standard Time', zoneName: '(UTC+11:00) Magadan'},
    {zoneId: 'Norfolk Standard Time', zoneName: '(UTC+11:00) Norfolk Island'},
    {zoneId: 'Sakhalin Standard Time', zoneName: '(UTC+11:00) Sakhalin'},
    {zoneId: 'Central Pacific Standard Time', zoneName: '(UTC+11:00) Solomon Is., New Caledonia'},
    {zoneId: 'Russia Time Zone 11', zoneName: '(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky'},
    {zoneId: 'New Zealand Standard Time', zoneName: '(UTC+12:00) Auckland, Wellington'},
    {zoneId: 'UTC+12', zoneName: '(UTC+12:00) Coordinated Universal Time+12'},
    {zoneId: 'Fiji Standard Time', zoneName: '(UTC+12:00) Fiji'},
    {zoneId: 'Kamchatka Standard Time', zoneName: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old'},
    {zoneId: 'Chatham Islands Standard Time', zoneName: '(UTC+12:45) Chatham Islands'},
    {zoneId: 'UTC+13', zoneName: '(UTC+13:00) Coordinated Universal Time+13'},
    {zoneId: 'Tonga Standard Time', zoneName: "(UTC+13:00) Nuku'alofa"},
    {zoneId: 'Line Islands Standard Time', zoneName: '(UTC+14:00) Kiritimati Island'},
];

export const ianaTime = [
    {mapZone: 'Dateline Standard Time', territory: '001', type: 'Etc/GMT+12'},
    {mapZone: 'Dateline Standard Time', territory: 'ZZ', type: 'Etc/GMT+12'},
    {mapZone: 'UTC-11', territory: '001', type: 'Etc/GMT+11'},
    {mapZone: 'UTC-11', territory: 'AS', type: 'Pacific/Pago_Pago'},
    {mapZone: 'UTC-11', territory: 'NU', type: 'Pacific/Niue'},
    {mapZone: 'UTC-11', territory: 'UM', type: 'Pacific/Midway'},
    {mapZone: 'UTC-11', territory: 'ZZ', type: 'Etc/GMT+11'},
    {mapZone: 'Hawaiian Standard Time', territory: '001', type: 'Pacific/Honolulu'},
    {mapZone: 'Hawaiian Standard Time', territory: 'CK', type: 'Pacific/Rarotonga'},
    {mapZone: 'Hawaiian Standard Time', territory: 'PF', type: 'Pacific/Tahiti'},
    {mapZone: 'Hawaiian Standard Time', territory: 'UM', type: 'Pacific/Johnston'},
    {mapZone: 'Hawaiian Standard Time', territory: 'US', type: 'Pacific/Honolulu'},
    {mapZone: 'Hawaiian Standard Time', territory: 'ZZ', type: 'Etc/GMT+10'},
    {mapZone: 'Alaskan Standard Time', territory: '001', type: 'America/Anchorage'},
    {mapZone: 'Alaskan Standard Time', territory: 'US', type: 'America/Anchorage America/Juneau America/Metlakatla America/Nome America/Sitka America/Yakutat'},
    {mapZone: 'Pacific Standard Time', territory: '001', type: 'America/Los_Angeles'},
    {mapZone: 'Pacific Standard Time', territory: 'CA', type: 'America/Vancouver America/Dawson America/Whitehorse'},
    {mapZone: 'Pacific Standard Time', territory: 'MX', type: 'America/Tijuana America/Santa_Isabel'},
    {mapZone: 'Pacific Standard Time', territory: 'US', type: 'America/Los_Angeles'},
    {mapZone: 'Pacific Standard Time', territory: 'ZZ', type: 'PST8PDT'},
    {mapZone: 'US Mountain Standard Time', territory: '001', type: 'America/Phoenix'},
    {mapZone: 'US Mountain Standard Time', territory: 'CA', type: 'America/Dawson_Creek America/Creston America/Fort_Nelson'},
    {mapZone: 'US Mountain Standard Time', territory: 'MX', type: 'America/Hermosillo'},
    {mapZone: 'US Mountain Standard Time', territory: 'US', type: 'America/Phoenix'},
    {mapZone: 'US Mountain Standard Time', territory: 'ZZ', type: 'Etc/GMT+7'},
    {mapZone: 'Mountain Standard Time (Mexico)', territory: '001', type: 'America/Chihuahua'},
    {mapZone: 'Mountain Standard Time (Mexico)', territory: 'MX', type: 'America/Chihuahua America/Mazatlan'},
    {mapZone: 'Mountain Standard Time', territory: '001', type: 'America/Denver'},
    {mapZone: 'Mountain Standard Time', territory: 'CA', type: 'America/Edmonton America/Cambridge_Bay America/Inuvik America/Yellowknife'},
    {mapZone: 'Mountain Standard Time', territory: 'MX', type: 'America/Ojinaga'},
    {mapZone: 'Mountain Standard Time', territory: 'US', type: 'America/Denver America/Boise'},
    {mapZone: 'Mountain Standard Time', territory: 'ZZ', type: 'MST7MDT'},
    {mapZone: 'Central America Standard Time', territory: '001', type: 'America/Guatemala'},
    {mapZone: 'Central America Standard Time', territory: 'BZ', type: 'America/Belize'},
    {mapZone: 'Central America Standard Time', territory: 'CR', type: 'America/Costa_Rica'},
    {mapZone: 'Central America Standard Time', territory: 'EC', type: 'Pacific/Galapagos'},
    {mapZone: 'Central America Standard Time', territory: 'GT', type: 'America/Guatemala'},
    {mapZone: 'Central America Standard Time', territory: 'HN', type: 'America/Tegucigalpa'},
    {mapZone: 'Central America Standard Time', territory: 'NI', type: 'America/Managua'},
    {mapZone: 'Central America Standard Time', territory: 'SV', type: 'America/El_Salvador'},
    {mapZone: 'Central America Standard Time', territory: 'ZZ', type: 'Etc/GMT+6'},
    {mapZone: 'Central Standard Time', territory: '001', type: 'America/Chicago'},
    {mapZone: 'Central Standard Time', territory: 'CA', type: 'America/Winnipeg America/Rainy_River America/Rankin_Inlet America/Resolute'},
    {mapZone: 'Central Standard Time', territory: 'MX', type: 'America/Matamoros'},
    {mapZone: 'Central Standard Time', territory: 'US', type: 'America/Chicago America/Indiana/Knox America/Indiana/Tell_City America/Menominee America/North_Dakota/Beulah America/North_Dakota/Center America/North_Dakota/New_Salem'},
    {mapZone: 'Central Standard Time', territory: 'ZZ', type: 'CST6CDT'},
    {mapZone: 'Central Standard Time (Mexico)', territory: '001', type: 'America/Mexico_City'},
    {mapZone: 'Central Standard Time (Mexico)', territory: 'MX', type: 'America/Mexico_City America/Bahia_Banderas America/Merida America/Monterrey'},
    {mapZone: 'Canada Central Standard Time', territory: '001', type: 'America/Regina'},
    {mapZone: 'Canada Central Standard Time', territory: 'CA', type: 'America/Regina America/Swift_Current'},
    {mapZone: 'SA Pacific Standard Time', territory: '001', type: 'America/Bogota'},
    {mapZone: 'SA Pacific Standard Time', territory: 'BR', type: 'America/Rio_Branco America/Eirunepe'},
    {mapZone: 'SA Pacific Standard Time', territory: 'CA', type: 'America/Coral_Harbour'},
    {mapZone: 'SA Pacific Standard Time', territory: 'CL', type: 'Pacific/Easter'},
    {mapZone: 'SA Pacific Standard Time', territory: 'CO', type: 'America/Bogota'},
    {mapZone: 'SA Pacific Standard Time', territory: 'EC', type: 'America/Guayaquil'},
    {mapZone: 'SA Pacific Standard Time', territory: 'HT', type: 'America/Port-au-Prince'},
    {mapZone: 'SA Pacific Standard Time', territory: 'JM', type: 'America/Jamaica'},
    {mapZone: 'SA Pacific Standard Time', territory: 'KY', type: 'America/Cayman'},
    {mapZone: 'SA Pacific Standard Time', territory: 'PA', type: 'America/Panama'},
    {mapZone: 'SA Pacific Standard Time', territory: 'PE', type: 'America/Lima'},
    {mapZone: 'SA Pacific Standard Time', territory: 'ZZ', type: 'Etc/GMT+5'},
    {mapZone: 'Eastern Standard Time (Mexico)', territory: '001', type: 'America/Cancun'},
    {mapZone: 'Eastern Standard Time (Mexico)', territory: 'MX', type: 'America/Cancun'},
    {mapZone: 'Eastern Standard Time', territory: '001', type: 'America/New_York'},
    {mapZone: 'Eastern Standard Time', territory: 'BS', type: 'America/Nassau'},
    {mapZone: 'Eastern Standard Time', territory: 'CA', type: 'America/Toronto America/Iqaluit America/Montreal America/Nipigon America/Pangnirtung America/Thunder_Bay'},
    {mapZone: 'Eastern Standard Time', territory: 'CU', type: 'America/Havana'},
    {mapZone: 'Eastern Standard Time', territory: 'US', type: 'America/New_York America/Detroit America/Indiana/Petersburg America/Indiana/Vincennes America/Indiana/Winamac America/Kentucky/Monticello America/Louisville'},
    {mapZone: 'Eastern Standard Time', territory: 'ZZ', type: 'EST5EDT'},
    {mapZone: 'US Eastern Standard Time', territory: '001', type: 'America/Indianapolis'},
    {mapZone: 'US Eastern Standard Time', territory: 'US', type: 'America/Indianapolis America/Indiana/Marengo America/Indiana/Vevay'},
    {mapZone: 'Venezuela Standard Time', territory: '001', type: 'America/Caracas'},
    {mapZone: 'Venezuela Standard Time', territory: 'VE', type: 'America/Caracas'},
    {mapZone: 'Paraguay Standard Time', territory: '001', type: 'America/Asuncion'},
    {mapZone: 'Paraguay Standard Time', territory: 'PY', type: 'America/Asuncion'},
    {mapZone: 'Atlantic Standard Time', territory: '001', type: 'America/Halifax'},
    {mapZone: 'Atlantic Standard Time', territory: 'BM', type: 'Atlantic/Bermuda'},
    {mapZone: 'Atlantic Standard Time', territory: 'CA', type: 'America/Halifax America/Glace_Bay America/Goose_Bay America/Moncton'},
    {mapZone: 'Atlantic Standard Time', territory: 'GL', type: 'America/Thule'},
    {mapZone: 'Central Brazilian Standard Time', territory: '001', type: 'America/Cuiaba'},
    {mapZone: 'Central Brazilian Standard Time', territory: 'BR', type: 'America/Cuiaba America/Campo_Grande'},
    {mapZone: 'SA Western Standard Time', territory: '001', type: 'America/La_Paz'},
    {mapZone: 'SA Western Standard Time', territory: 'AG', type: 'America/Antigua'},
    {mapZone: 'SA Western Standard Time', territory: 'AI', type: 'America/Anguilla'},
    {mapZone: 'SA Western Standard Time', territory: 'AW', type: 'America/Aruba'},
    {mapZone: 'SA Western Standard Time', territory: 'BB', type: 'America/Barbados'},
    {mapZone: 'SA Western Standard Time', territory: 'BL', type: 'America/St_Barthelemy'},
    {mapZone: 'SA Western Standard Time', territory: 'BO', type: 'America/La_Paz'},
    {mapZone: 'SA Western Standard Time', territory: 'BQ', type: 'America/Kralendijk'},
    {mapZone: 'SA Western Standard Time', territory: 'BR', type: 'America/Manaus America/Boa_Vista America/Porto_Velho'},
    {mapZone: 'SA Western Standard Time', territory: 'CA', type: 'America/Blanc-Sablon'},
    {mapZone: 'SA Western Standard Time', territory: 'CW', type: 'America/Curacao'},
    {mapZone: 'SA Western Standard Time', territory: 'DM', type: 'America/Dominica'},
    {mapZone: 'SA Western Standard Time', territory: 'DO', type: 'America/Santo_Domingo'},
    {mapZone: 'SA Western Standard Time', territory: 'GD', type: 'America/Grenada'},
    {mapZone: 'SA Western Standard Time', territory: 'GP', type: 'America/Guadeloupe'},
    {mapZone: 'SA Western Standard Time', territory: 'GY', type: 'America/Guyana'},
    {mapZone: 'SA Western Standard Time', territory: 'KN', type: 'America/St_Kitts'},
    {mapZone: 'SA Western Standard Time', territory: 'LC', type: 'America/St_Lucia'},
    {mapZone: 'SA Western Standard Time', territory: 'MF', type: 'America/Marigot'},
    {mapZone: 'SA Western Standard Time', territory: 'MQ', type: 'America/Martinique'},
    {mapZone: 'SA Western Standard Time', territory: 'MS', type: 'America/Montserrat'},
    {mapZone: 'SA Western Standard Time', territory: 'PR', type: 'America/Puerto_Rico'},
    {mapZone: 'SA Western Standard Time', territory: 'SX', type: 'America/Lower_Princes'},
    {mapZone: 'SA Western Standard Time', territory: 'TC', type: 'America/Grand_Turk'},
    {mapZone: 'SA Western Standard Time', territory: 'TT', type: 'America/Port_of_Spain'},
    {mapZone: 'SA Western Standard Time', territory: 'VC', type: 'America/St_Vincent'},
    {mapZone: 'SA Western Standard Time', territory: 'VG', type: 'America/Tortola'},
    {mapZone: 'SA Western Standard Time', territory: 'VI', type: 'America/St_Thomas'},
    {mapZone: 'SA Western Standard Time', territory: 'ZZ', type: 'Etc/GMT+4'},
    {mapZone: 'Newfoundland Standard Time', territory: '001', type: 'America/St_Johns'},
    {mapZone: 'Newfoundland Standard Time', territory: 'CA', type: 'America/St_Johns'},
    {mapZone: 'E. South America Standard Time', territory: '001', type: 'America/Sao_Paulo'},
    {mapZone: 'E. South America Standard Time', territory: 'BR', type: 'America/Sao_Paulo'},
    {mapZone: 'SA Eastern Standard Time', territory: '001', type: 'America/Cayenne'},
    {mapZone: 'SA Eastern Standard Time', territory: 'AQ', type: 'Antarctica/Ra'},
    {mapZone: 'SA Eastern Standard Time', territory: 'BR', type: 'America/Fortaleza America/Araguaina America/Belem America/Maceio America/Recife America/Santarem'},
    {mapZone: 'SA Eastern Standard Time', territory: 'FK', type: 'Atlantic/Stanley'},
    {mapZone: 'SA Eastern Standard Time', territory: 'GF', type: 'America/Cayenne'},
    {mapZone: 'SA Eastern Standard Time', territory: 'SR', type: 'America/Paramaribo'},
    {mapZone: 'SA Eastern Standard Time', territory: 'ZZ', type: 'Etc/GMT+3'},
    {mapZone: 'Argentina Standard Time', territory: '001', type: 'America/Buenos_Aires'},
    {mapZone: 'Argentina Standard Time', territory: 'AR', type: 'America/Buenos_Aires America/Argentina/La_Rioja America/Argentina/Rio_Gallegos America/Argentina/Salta America/Argentina/San_Juan America/Argentina/San_Luis America/Argentina/Tucuman America/Argentina/Ushuaia America/Catamarca America/Cordoba America/Jujuy America/Mendoza'},
    {mapZone: 'Greenland Standard Time', territory: '001', type: 'America/Godthab'},
    {mapZone: 'Greenland Standard Time', territory: 'GL', type: 'America/Godthab'},
    {mapZone: 'Montevideo Standard Time', territory: '001', type: 'America/Montevideo'},
    {mapZone: 'Montevideo Standard Time', territory: 'UY', type: 'America/Montevideo'},
    {mapZone: 'Bahia Standard Time', territory: '001', type: 'America/Bahia'},
    {mapZone: 'Bahia Standard Time', territory: 'BR', type: 'America/Bahia'},
    {mapZone: 'Pacific SA Standard Time', territory: '001', type: 'America/Santiago'},
    {mapZone: 'Pacific SA Standard Time', territory: 'AQ', type: 'Antarctica/Palmer'},
    {mapZone: 'Pacific SA Standard Time', territory: 'CL', type: 'America/Santiago'},
    {mapZone: 'UTC-02', territory: '001', type: 'Etc/GMT+2'},
    {mapZone: 'UTC-02', territory: 'BR', type: 'America/Noronha'},
    {mapZone: 'UTC-02', territory: 'GS', type: 'Atlantic/South_Georgia'},
    {mapZone: 'UTC-02', territory: 'ZZ', type: 'Etc/GMT+2'},
    {mapZone: 'Azores Standard Time', territory: '001', type: 'Atlantic/Azores'},
    {mapZone: 'Azores Standard Time', territory: 'GL', type: 'America/Scoresbysund'},
    {mapZone: 'Azores Standard Time', territory: 'PT', type: 'Atlantic/Azores'},
    {mapZone: 'Cape Verde Standard Time', territory: '001', type: 'Atlantic/Cape_Verde'},
    {mapZone: 'Cape Verde Standard Time', territory: 'CV', type: 'Atlantic/Cape_Verde'},
    {mapZone: 'Cape Verde Standard Time', territory: 'ZZ', type: 'Etc/GMT+1'},
    {mapZone: 'Morocco Standard Time', territory: '001', type: 'Africa/Casablanca'},
    {mapZone: 'Morocco Standard Time', territory: 'EH', type: 'Africa/El_Aaiun'},
    {mapZone: 'Morocco Standard Time', territory: 'MA', type: 'Africa/Casablanca'},
    {mapZone: 'UTC', territory: '001', type: 'Etc/GMT'},
    {mapZone: 'UTC', territory: 'GL', type: 'America/Danmarkshavn'},
    {mapZone: 'UTC', territory: 'ZZ', type: 'Etc/GMT'},
    {mapZone: 'GMT Standard Time', territory: '001', type: 'Europe/London'},
    {mapZone: 'GMT Standard Time', territory: 'ES', type: 'Atlantic/Canary'},
    {mapZone: 'GMT Standard Time', territory: 'FO', type: 'Atlantic/Faeroe'},
    {mapZone: 'GMT Standard Time', territory: 'GB', type: 'Europe/London'},
    {mapZone: 'GMT Standard Time', territory: 'GG', type: 'Europe/Guernsey'},
    {mapZone: 'GMT Standard Time', territory: 'IE', type: 'Europe/Dublin'},
    {mapZone: 'GMT Standard Time', territory: 'IM', type: 'Europe/Isle_of_Man'},
    {mapZone: 'GMT Standard Time', territory: 'JE', type: 'Europe/Jersey'},
    {mapZone: 'GMT Standard Time', territory: 'PT', type: 'Europe/Lisbon Atlantic/Madeira'},
    {mapZone: 'Greenwich Standard Time', territory: '001', type: 'Atlantic/Reykjavik'},
    {mapZone: 'Greenwich Standard Time', territory: 'BF', type: 'Africa/Ouagadougou'},
    {mapZone: 'Greenwich Standard Time', territory: 'CI', type: 'Africa/Abidjan'},
    {mapZone: 'Greenwich Standard Time', territory: 'GH', type: 'Africa/Accra'},
    {mapZone: 'Greenwich Standard Time', territory: 'GM', type: 'Africa/Banjul'},
    {mapZone: 'Greenwich Standard Time', territory: 'GN', type: 'Africa/Conakry'},
    {mapZone: 'Greenwich Standard Time', territory: 'GW', type: 'Africa/Bissau'},
    {mapZone: 'Greenwich Standard Time', territory: 'IS', type: 'Atlantic/Reykjavik'},
    {mapZone: 'Greenwich Standard Time', territory: 'LR', type: 'Africa/Monrovia'},
    {mapZone: 'Greenwich Standard Time', territory: 'ML', type: 'Africa/Bamako'},
    {mapZone: 'Greenwich Standard Time', territory: 'MR', type: 'Africa/Nouakchott'},
    {mapZone: 'Greenwich Standard Time', territory: 'SH', type: 'Atlantic/St_Helena'},
    {mapZone: 'Greenwich Standard Time', territory: 'SL', type: 'Africa/Freetown'},
    {mapZone: 'Greenwich Standard Time', territory: 'SN', type: 'Africa/Dakar'},
    {mapZone: 'Greenwich Standard Time', territory: 'ST', type: 'Africa/Sao_Tome'},
    {mapZone: 'Greenwich Standard Time', territory: 'TG', type: 'Africa/Lome'},
    {mapZone: 'W. Europe Standard Time', territory: '001', type: 'Europe/Berlin'},
    {mapZone: 'W. Europe Standard Time', territory: 'AD', type: 'Europe/Andorra'},
    {mapZone: 'W. Europe Standard Time', territory: 'AT', type: 'Europe/Vienna'},
    {mapZone: 'W. Europe Standard Time', territory: 'CH', type: 'Europe/Zurich'},
    {mapZone: 'W. Europe Standard Time', territory: 'DE', type: 'Europe/Berlin Europe/Busingen'},
    {mapZone: 'W. Europe Standard Time', territory: 'GI', type: 'Europe/Gibraltar'},
    {mapZone: 'W. Europe Standard Time', territory: 'IT', type: 'Europe/Rome'},
    {mapZone: 'W. Europe Standard Time', territory: 'LI', type: 'Europe/Vaduz'},
    {mapZone: 'W. Europe Standard Time', territory: 'LU', type: 'Europe/Luxembourg'},
    {mapZone: 'W. Europe Standard Time', territory: 'MC', type: 'Europe/Monaco'},
    {mapZone: 'W. Europe Standard Time', territory: 'MT', type: 'Europe/Malta'},
    {mapZone: 'W. Europe Standard Time', territory: 'NL', type: 'Europe/Amsterdam'},
    {mapZone: 'W. Europe Standard Time', territory: 'NO', type: 'Europe/Oslo'},
    {mapZone: 'W. Europe Standard Time', territory: 'SE', type: 'Europe/Stockholm'},
    {mapZone: 'W. Europe Standard Time', territory: 'SJ', type: 'Arctic/Longyearbyen'},
    {mapZone: 'W. Europe Standard Time', territory: 'SM', type: 'Europe/San_Marino'},
    {mapZone: 'W. Europe Standard Time', territory: 'VA', type: 'Europe/Vatican'},
    {mapZone: 'Central Europe Standard Time', territory: '001', type: 'Europe/Budapest'},
    {mapZone: 'Central Europe Standard Time', territory: 'AL', type: 'Europe/Tirane'},
    {mapZone: 'Central Europe Standard Time', territory: 'CZ', type: 'Europe/Prague'},
    {mapZone: 'Central Europe Standard Time', territory: 'HU', type: 'Europe/Budapest'},
    {mapZone: 'Central Europe Standard Time', territory: 'ME', type: 'Europe/Podgorica'},
    {mapZone: 'Central Europe Standard Time', territory: 'RS', type: 'Europe/Belgrade'},
    {mapZone: 'Central Europe Standard Time', territory: 'SI', type: 'Europe/Ljubljana'},
    {mapZone: 'Central Europe Standard Time', territory: 'SK', type: 'Europe/Bratislava'},
    {mapZone: 'Romance Standard Time', territory: '001', type: 'Europe/Paris'},
    {mapZone: 'Romance Standard Time', territory: 'BE', type: 'Europe/Brussels'},
    {mapZone: 'Romance Standard Time', territory: 'DK', type: 'Europe/Copenhagen'},
    {mapZone: 'Romance Standard Time', territory: 'ES', type: 'Europe/Madrid Africa/Ceuta'},
    {mapZone: 'Romance Standard Time', territory: 'FR', type: 'Europe/Paris'},
    {mapZone: 'Central European Standard Time', territory: '001', type: 'Europe/Warsaw'},
    {mapZone: 'Central European Standard Time', territory: 'BA', type: 'Europe/Sarajevo'},
    {mapZone: 'Central European Standard Time', territory: 'HR', type: 'Europe/Zagreb'},
    {mapZone: 'Central European Standard Time', territory: 'MK', type: 'Europe/Skopje'},
    {mapZone: 'Central European Standard Time', territory: 'PL', type: 'Europe/Warsaw'},
    {mapZone: 'W. Central Africa Standard Time', territory: '001', type: 'Africa/Lagos'},
    {mapZone: 'W. Central Africa Standard Time', territory: 'AO', type: 'Africa/Luanda'},
    {mapZone: 'W. Central Africa Standard Time', territory: 'BJ', type: 'Africa/Porto-Novo'},
    {mapZone: 'W. Central Africa Standard Time', territory: 'CD', type: 'Africa/Kinshasa'},
    {mapZone: 'W. Central Africa Standard Time', territory: 'CF', type: 'Africa/Bangui'},
    {mapZone: 'W. Central Africa Standard Time', territory: 'CG', type: 'Africa/Brazzaville'},
    {mapZone: 'W. Central Africa Standard Time', territory: 'CM', type: 'Africa/Douala'},
    {mapZone: 'W. Central Africa Standard Time', territory: 'DZ', type: 'Africa/Algiers'},
    {mapZone: 'W. Central Africa Standard Time', territory: 'GA', type: 'Africa/Libreville'},
    {mapZone: 'W. Central Africa Standard Time', territory: 'GQ', type: 'Africa/Malabo'},
    {mapZone: 'W. Central Africa Standard Time', territory: 'NE', type: 'Africa/Niamey'},
    {mapZone: 'W. Central Africa Standard Time', territory: 'NG', type: 'Africa/Lagos'},
    {mapZone: 'W. Central Africa Standard Time', territory: 'TD', type: 'Africa/Ndjamena'},
    {mapZone: 'W. Central Africa Standard Time', territory: 'TN', type: 'Africa/Tunis'},
    {mapZone: 'W. Central Africa Standard Time', territory: 'ZZ', type: 'Etc/GMT-1'},
    {mapZone: 'Namibia Standard Time', territory: '001', type: 'Africa/Windhoek'},
    {mapZone: 'Namibia Standard Time', territory: 'NA', type: 'Africa/Windhoek'},
    {mapZone: 'Jordan Standard Time', territory: '001', type: 'Asia/Amman'},
    {mapZone: 'Jordan Standard Time', territory: 'JO', type: 'Asia/Amman'},
    {mapZone: 'GTB Standard Time', territory: '001', type: 'Europe/Bucharest'},
    {mapZone: 'GTB Standard Time', territory: 'CY', type: 'Asia/Nicosia'},
    {mapZone: 'GTB Standard Time', territory: 'GR', type: 'Europe/Athens'},
    {mapZone: 'GTB Standard Time', territory: 'RO', type: 'Europe/Bucharest'},
    {mapZone: 'Middle East Standard Time', territory: '001', type: 'Asia/Beirut'},
    {mapZone: 'Middle East Standard Time', territory: 'LB', type: 'Asia/Beirut'},
    {mapZone: 'Egypt Standard Time', territory: '001', type: 'Africa/Cairo'},
    {mapZone: 'Egypt Standard Time', territory: 'EG', type: 'Africa/Cairo'},
    {mapZone: 'Syria Standard Time', territory: '001', type: 'Asia/Damascus'},
    {mapZone: 'Syria Standard Time', territory: 'SY', type: 'Asia/Damascus'},
    {mapZone: 'E. Europe Standard Time', territory: '001', type: 'Europe/Chisinau'},
    {mapZone: 'E. Europe Standard Time', territory: 'MD', type: 'Europe/Chisinau'},
    {mapZone: 'South Africa Standard Time', territory: '001', type: 'Africa/Johannesburg'},
    {mapZone: 'South Africa Standard Time', territory: 'BI', type: 'Africa/Bujumbura'},
    {mapZone: 'South Africa Standard Time', territory: 'BW', type: 'Africa/Gaborone'},
    {mapZone: 'South Africa Standard Time', territory: 'CD', type: 'Africa/Lubumbashi'},
    {mapZone: 'South Africa Standard Time', territory: 'LS', type: 'Africa/Maseru'},
    {mapZone: 'South Africa Standard Time', territory: 'MW', type: 'Africa/Blantyre'},
    {mapZone: 'South Africa Standard Time', territory: 'MZ', type: 'Africa/Maputo'},
    {mapZone: 'South Africa Standard Time', territory: 'RW', type: 'Africa/Kigali'},
    {mapZone: 'South Africa Standard Time', territory: 'SZ', type: 'Africa/Mbabane'},
    {mapZone: 'South Africa Standard Time', territory: 'ZA', type: 'Africa/Johannesburg'},
    {mapZone: 'South Africa Standard Time', territory: 'ZM', type: 'Africa/Lusaka'},
    {mapZone: 'South Africa Standard Time', territory: 'ZW', type: 'Africa/Harare'},
    {mapZone: 'South Africa Standard Time', territory: 'ZZ', type: 'Etc/GMT-2'},
    {mapZone: 'FLE Standard Time', territory: '001', type: 'Europe/Kiev'},
    {mapZone: 'FLE Standard Time', territory: 'AX', type: 'Europe/Mariehamn'},
    {mapZone: 'FLE Standard Time', territory: 'BG', type: 'Europe/Sofia'},
    {mapZone: 'FLE Standard Time', territory: 'EE', type: 'Europe/Tallinn'},
    {mapZone: 'FLE Standard Time', territory: 'FI', type: 'Europe/Helsinki'},
    {mapZone: 'FLE Standard Time', territory: 'LT', type: 'Europe/Vilnius'},
    {mapZone: 'FLE Standard Time', territory: 'LV', type: 'Europe/Riga'},
    {mapZone: 'FLE Standard Time', territory: 'UA', type: 'Europe/Kiev Europe/Uzhgorod Europe/Zaporozhye'},
    {mapZone: 'Turkey Standard Time', territory: '001', type: 'Europe/Istanbul'},
    {mapZone: 'Turkey Standard Time', territory: 'TR', type: 'Europe/Istanbul'},
    {mapZone: 'Israel Standard Time', territory: '001', type: 'Asia/Jerusalem'},
    {mapZone: 'Israel Standard Time', territory: 'IL', type: 'Asia/Jerusalem'},
    {mapZone: 'Kaliningrad Standard Time', territory: '001', type: 'Europe/Kaliningrad'},
    {mapZone: 'Kaliningrad Standard Time', territory: 'RU', type: 'Europe/Kaliningrad'},
    {mapZone: 'Libya Standard Time', territory: '001', type: 'Africa/Tripoli'},
    {mapZone: 'Libya Standard Time', territory: 'LY', type: 'Africa/Tripoli'},
    {mapZone: 'Arabic Standard Time', territory: '001', type: 'Asia/Baghdad'},
    {mapZone: 'Arabic Standard Time', territory: 'IQ', type: 'Asia/Baghdad'},
    {mapZone: 'Arab Standard Time', territory: '001', type: 'Asia/Riyadh'},
    {mapZone: 'Arab Standard Time', territory: 'BH', type: 'Asia/Bahrain'},
    {mapZone: 'Arab Standard Time', territory: 'KW', type: 'Asia/Kuwait'},
    {mapZone: 'Arab Standard Time', territory: 'QA', type: 'Asia/Qatar'},
    {mapZone: 'Arab Standard Time', territory: 'SA', type: 'Asia/Riyadh'},
    {mapZone: 'Arab Standard Time', territory: 'YE', type: 'Asia/Aden'},
    {mapZone: 'Belarus Standard Time', territory: '001', type: 'Europe/Minsk'},
    {mapZone: 'Belarus Standard Time', territory: 'BY', type: 'Europe/Minsk'},
    {mapZone: 'Russian Standard Time', territory: '001', type: 'Europe/Moscow'},
    {mapZone: 'Russian Standard Time', territory: 'RU', type: 'Europe/Moscow Europe/Kirov Europe/Simferopol Europe/Volgograd'},
    {mapZone: 'E. Africa Standard Time', territory: '001', type: 'Africa/Nairobi'},
    {mapZone: 'E. Africa Standard Time', territory: 'AQ', type: 'Antarctica/Syowa'},
    {mapZone: 'E. Africa Standard Time', territory: 'DJ', type: 'Africa/Djibouti'},
    {mapZone: 'E. Africa Standard Time', territory: 'ER', type: 'Africa/Asmera'},
    {mapZone: 'E. Africa Standard Time', territory: 'ET', type: 'Africa/Addis_Ababa'},
    {mapZone: 'E. Africa Standard Time', territory: 'KE', type: 'Africa/Nairobi'},
    {mapZone: 'E. Africa Standard Time', territory: 'KM', type: 'Indian/Comoro'},
    {mapZone: 'E. Africa Standard Time', territory: 'MG', type: 'Indian/Antananarivo'},
    {mapZone: 'E. Africa Standard Time', territory: 'SD', type: 'Africa/Khartoum'},
    {mapZone: 'E. Africa Standard Time', territory: 'SO', type: 'Africa/Mogadishu'},
    {mapZone: 'E. Africa Standard Time', territory: 'SS', type: 'Africa/Juba'},
    {mapZone: 'E. Africa Standard Time', territory: 'TZ', type: 'Africa/Dar_es_Salaam'},
    {mapZone: 'E. Africa Standard Time', territory: 'UG', type: 'Africa/Kampala'},
    {mapZone: 'E. Africa Standard Time', territory: 'YT', type: 'Indian/Mayotte'},
    {mapZone: 'E. Africa Standard Time', territory: 'ZZ', type: 'Etc/GMT-3'},
    {mapZone: 'Astrakhan Standard Time', territory: '001', type: 'Europe/Astrakhan'},
    {mapZone: 'Astrakhan Standard Time', territory: 'RU', type: 'Europe/Astrakhan Europe/Ulyanovsk'},
    {mapZone: 'Iran Standard Time', territory: '001', type: 'Asia/Tehran'},
    {mapZone: 'Iran Standard Time', territory: 'IR', type: 'Asia/Tehran'},
    {mapZone: 'Arabian Standard Time', territory: '001', type: 'Asia/Dubai'},
    {mapZone: 'Arabian Standard Time', territory: 'AE', type: 'Asia/Dubai'},
    {mapZone: 'Arabian Standard Time', territory: 'OM', type: 'Asia/Muscat'},
    {mapZone: 'Arabian Standard Time', territory: 'ZZ', type: 'Etc/GMT-4'},
    {mapZone: 'Azerbaijan Standard Time', territory: '001', type: 'Asia/Baku'},
    {mapZone: 'Azerbaijan Standard Time', territory: 'AZ', type: 'Asia/Baku'},
    {mapZone: 'Russia Time Zone 3', territory: '001', type: 'Europe/Samara'},
    {mapZone: 'Russia Time Zone 3', territory: 'RU', type: 'Europe/Samara'},
    {mapZone: 'Mauritius Standard Time', territory: '001', type: 'Indian/Mauritius'},
    {mapZone: 'Mauritius Standard Time', territory: 'MU', type: 'Indian/Mauritius'},
    {mapZone: 'Mauritius Standard Time', territory: 'RE', type: 'Indian/Reunion'},
    {mapZone: 'Mauritius Standard Time', territory: 'SC', type: 'Indian/Mahe'},
    {mapZone: 'Georgian Standard Time', territory: '001', type: 'Asia/Tbilisi'},
    {mapZone: 'Georgian Standard Time', territory: 'GE', type: 'Asia/Tbilisi'},
    {mapZone: 'Caucasus Standard Time', territory: '001', type: 'Asia/Yerevan'},
    {mapZone: 'Caucasus Standard Time', territory: 'AM', type: 'Asia/Yerevan'},
    {mapZone: 'Afghanistan Standard Time', territory: '001', type: 'Asia/Kabul'},
    {mapZone: 'Afghanistan Standard Time', territory: 'AF', type: 'Asia/Kabul'},
    {mapZone: 'West Asia Standard Time', territory: '001', type: 'Asia/Tashkent'},
    {mapZone: 'West Asia Standard Time', territory: 'AQ', type: 'Antarctica/Mawson'},
    {mapZone: 'West Asia Standard Time', territory: 'KZ', type: 'Asia/Oral Asia/Aqtau Asia/Aqtobe'},
    {mapZone: 'West Asia Standard Time', territory: 'MV', type: 'Indian/Maldives'},
    {mapZone: 'West Asia Standard Time', territory: 'TF', type: 'Indian/Kerguelen'},
    {mapZone: 'West Asia Standard Time', territory: 'TJ', type: 'Asia/Dushanbe'},
    {mapZone: 'West Asia Standard Time', territory: 'TM', type: 'Asia/Ashgabat'},
    {mapZone: 'West Asia Standard Time', territory: 'UZ', type: 'Asia/Tashkent Asia/Samarkand'},
    {mapZone: 'West Asia Standard Time', territory: 'ZZ', type: 'Etc/GMT-5'},
    {mapZone: 'Ekaterinburg Standard Time', territory: '001', type: 'Asia/Yekaterinburg'},
    {mapZone: 'Ekaterinburg Standard Time', territory: 'RU', type: 'Asia/Yekaterinburg'},
    {mapZone: 'Pakistan Standard Time', territory: '001', type: 'Asia/Karachi'},
    {mapZone: 'Pakistan Standard Time', territory: 'PK', type: 'Asia/Karachi'},
    {mapZone: 'India Standard Time', territory: '001', type: 'Asia/Calcutta'},
    {mapZone: 'India Standard Time', territory: 'IN', type: 'Asia/Calcutta'},
    {mapZone: 'Sri Lanka Standard Time', territory: '001', type: 'Asia/Colombo'},
    {mapZone: 'Sri Lanka Standard Time', territory: 'LK', type: 'Asia/Colombo'},
    {mapZone: 'Nepal Standard Time', territory: '001', type: 'Asia/Katmandu'},
    {mapZone: 'Nepal Standard Time', territory: 'NP', type: 'Asia/Katmandu'},
    {mapZone: 'Central Asia Standard Time', territory: '001', type: 'Asia/Almaty'},
    {mapZone: 'Central Asia Standard Time', territory: 'AQ', type: 'Antarctica/Vostok'},
    {mapZone: 'Central Asia Standard Time', territory: 'CN', type: 'Asia/Urumqi'},
    {mapZone: 'Central Asia Standard Time', territory: 'IO', type: 'Indian/Chagos'},
    {mapZone: 'Central Asia Standard Time', territory: 'KG', type: 'Asia/Bishkek'},
    {mapZone: 'Central Asia Standard Time', territory: 'KZ', type: 'Asia/Almaty Asia/Qyzylorda'},
    {mapZone: 'Central Asia Standard Time', territory: 'ZZ', type: 'Etc/GMT-6'},
    {mapZone: 'Bangladesh Standard Time', territory: '001', type: 'Asia/Dhaka'},
    {mapZone: 'Bangladesh Standard Time', territory: 'BD', type: 'Asia/Dhaka'},
    {mapZone: 'Bangladesh Standard Time', territory: 'BT', type: 'Asia/Thimphu'},
    {mapZone: 'N. Central Asia Standard Time', territory: '001', type: 'Asia/Novosibirsk'},
    {mapZone: 'N. Central Asia Standard Time', territory: 'RU', type: 'Asia/Novosibirsk Asia/Omsk'},
    {mapZone: 'Altai Standard Time', territory: '001', type: 'Asia/Barnaul'},
    {mapZone: 'Altai Standard Time', territory: 'RU', type: 'Asia/Barnaul Asia/Tomsk'},
    {mapZone: 'Myanmar Standard Time', territory: '001', type: 'Asia/Rangoon'},
    {mapZone: 'Myanmar Standard Time', territory: 'CC', type: 'Indian/Cocos'},
    {mapZone: 'Myanmar Standard Time', territory: 'MM', type: 'Asia/Rangoon'},
    {mapZone: 'SE Asia Standard Time', territory: '001', type: 'Asia/Bangkok'},
    {mapZone: 'SE Asia Standard Time', territory: 'AQ', type: 'Antarctica/Davis'},
    {mapZone: 'SE Asia Standard Time', territory: 'CX', type: 'Indian/Christmas'},
    {mapZone: 'SE Asia Standard Time', territory: 'ID', type: 'Asia/Jakarta Asia/Pontianak'},
    {mapZone: 'SE Asia Standard Time', territory: 'KH', type: 'Asia/Phnom_Penh'},
    {mapZone: 'SE Asia Standard Time', territory: 'LA', type: 'Asia/Vientiane'},
    {mapZone: 'SE Asia Standard Time', territory: 'TH', type: 'Asia/Bangkok'},
    {mapZone: 'SE Asia Standard Time', territory: 'VN', type: 'Asia/Saigon'},
    {mapZone: 'SE Asia Standard Time', territory: 'ZZ', type: 'Etc/GMT-7'},
    {mapZone: 'North Asia Standard Time', territory: '001', type: 'Asia/Krasnoyarsk'},
    {mapZone: 'North Asia Standard Time', territory: 'RU', type: 'Asia/Krasnoyarsk Asia/Novokuznetsk'},
    {mapZone: 'China Standard Time', territory: '001', type: 'Asia/Shanghai'},
    {mapZone: 'China Standard Time', territory: 'CN', type: 'Asia/Shanghai'},
    {mapZone: 'China Standard Time', territory: 'HK', type: 'Asia/Hong_Kong'},
    {mapZone: 'China Standard Time', territory: 'MO', type: 'Asia/Macau'},
    {mapZone: 'North Asia East Standard Time', territory: '001', type: 'Asia/Irkutsk'},
    {mapZone: 'North Asia East Standard Time', territory: 'RU', type: 'Asia/Irkutsk'},
    {mapZone: 'Singapore Standard Time', territory: '001', type: 'Asia/Singapore'},
    {mapZone: 'Singapore Standard Time', territory: 'BN', type: 'Asia/Brunei'},
    {mapZone: 'Singapore Standard Time', territory: 'ID', type: 'Asia/Makassar'},
    {mapZone: 'Singapore Standard Time', territory: 'MY', type: 'Asia/Kuala_Lumpur Asia/Kuching'},
    {mapZone: 'Singapore Standard Time', territory: 'PH', type: 'Asia/Manila'},
    {mapZone: 'Singapore Standard Time', territory: 'SG', type: 'Asia/Singapore'},
    {mapZone: 'Singapore Standard Time', territory: 'ZZ', type: 'Etc/GMT-8'},
    {mapZone: 'W. Australia Standard Time', territory: '001', type: 'Australia/Perth'},
    {mapZone: 'W. Australia Standard Time', territory: 'AQ', type: 'Antarctica/Casey'},
    {mapZone: 'W. Australia Standard Time', territory: 'AU', type: 'Australia/Perth'},
    {mapZone: 'Taipei Standard Time', territory: '001', type: 'Asia/Taipei'},
    {mapZone: 'Taipei Standard Time', territory: 'TW', type: 'Asia/Taipei'},
    {mapZone: 'Ulaanbaatar Standard Time', territory: '001', type: 'Asia/Ulaanbaatar'},
    {mapZone: 'Ulaanbaatar Standard Time', territory: 'MN', type: 'Asia/Ulaanbaatar Asia/Choibalsan'},
    {mapZone: 'Transbaikal Standard Time', territory: '001', type: 'Asia/Chita'},
    {mapZone: 'Transbaikal Standard Time', territory: 'RU', type: 'Asia/Chita'},
    {mapZone: 'North Korea Standard Time', territory: '001', type: 'Asia/Pyongyang'},
    {mapZone: 'North Korea Standard Time', territory: 'KP', type: 'Asia/Pyongyang'},
    {mapZone: 'Tokyo Standard Time', territory: '001', type: 'Asia/Tokyo'},
    {mapZone: 'Tokyo Standard Time', territory: 'ID', type: 'Asia/Jayapura'},
    {mapZone: 'Tokyo Standard Time', territory: 'JP', type: 'Asia/Tokyo'},
    {mapZone: 'Tokyo Standard Time', territory: 'PW', type: 'Pacific/Palau'},
    {mapZone: 'Tokyo Standard Time', territory: 'TL', type: 'Asia/Dili'},
    {mapZone: 'Tokyo Standard Time', territory: 'ZZ', type: 'Etc/GMT-9'},
    {mapZone: 'Korea Standard Time', territory: '001', type: 'Asia/Seoul'},
    {mapZone: 'Korea Standard Time', territory: 'KR', type: 'Asia/Seoul'},
    {mapZone: 'Yakutsk Standard Time', territory: '001', type: 'Asia/Yakutsk'},
    {mapZone: 'Yakutsk Standard Time', territory: 'RU', type: 'Asia/Yakutsk Asia/Khandyga'},
    {mapZone: 'Cen. Australia Standard Time', territory: '001', type: 'Australia/Adelaide'},
    {mapZone: 'Cen. Australia Standard Time', territory: 'AU', type: 'Australia/Adelaide Australia/Broken_Hill'},
    {mapZone: 'AUS Central Standard Time', territory: '001', type: 'Australia/Darwin'},
    {mapZone: 'AUS Central Standard Time', territory: 'AU', type: 'Australia/Darwin'},
    {mapZone: 'E. Australia Standard Time', territory: '001', type: 'Australia/Brisbane'},
    {mapZone: 'E. Australia Standard Time', territory: 'AU', type: 'Australia/Brisbane Australia/Lindeman'},
    {mapZone: 'AUS Eastern Standard Time', territory: '001', type: 'Australia/Sydney'},
    {mapZone: 'AUS Eastern Standard Time', territory: 'AU', type: 'Australia/Sydney Australia/Melbourne'},
    {mapZone: 'West Pacific Standard Time', territory: '001', type: 'Pacific/Port_Moresby'},
    {mapZone: 'West Pacific Standard Time', territory: 'AQ', type: 'Antarctica/DumontDUrville'},
    {mapZone: 'West Pacific Standard Time', territory: 'FM', type: 'Pacific/Truk'},
    {mapZone: 'West Pacific Standard Time', territory: 'GU', type: 'Pacific/Guam'},
    {mapZone: 'West Pacific Standard Time', territory: 'MP', type: 'Pacific/Saipan'},
    {mapZone: 'West Pacific Standard Time', territory: 'PG', type: 'Pacific/Port_Moresby'},
    {mapZone: 'West Pacific Standard Time', territory: 'ZZ', type: 'Etc/GMT-10'},
    {mapZone: 'Tasmania Standard Time', territory: '001', type: 'Australia/Hobart'},
    {mapZone: 'Tasmania Standard Time', territory: 'AU', type: 'Australia/Hobart Australia/Currie'},
    {mapZone: 'Magadan Standard Time', territory: '001', type: 'Asia/Magadan'},
    {mapZone: 'Magadan Standard Time', territory: 'RU', type: 'Asia/Magadan'},
    {mapZone: 'Vladivostok Standard Time', territory: '001', type: 'Asia/Vladivostok'},
    {mapZone: 'Vladivostok Standard Time', territory: 'RU', type: 'Asia/Vladivostok Asia/Ust-Nera'},
    {mapZone: 'Sakhalin Standard Time', territory: '001', type: 'Asia/Sakhalin'},
    {mapZone: 'Sakhalin Standard Time', territory: 'RU', type: 'Asia/Sakhalin'},
    {mapZone: 'Russia Time Zone 10', territory: '001', type: 'Asia/Srednekolymsk'},
    {mapZone: 'Russia Time Zone 10', territory: 'RU', type: 'Asia/Srednekolymsk'},
    {mapZone: 'Central Pacific Standard Time', territory: '001', type: 'Pacific/Guadalcanal'},
    {mapZone: 'Central Pacific Standard Time', territory: 'AU', type: 'Antarctica/Macquarie'},
    {mapZone: 'Central Pacific Standard Time', territory: 'FM', type: 'Pacific/Ponape Pacific/Kosrae'},
    {mapZone: 'Central Pacific Standard Time', territory: 'NC', type: 'Pacific/Noumea'},
    {mapZone: 'Central Pacific Standard Time', territory: 'NF', type: 'Pacific/Norfolk'},
    {mapZone: 'Central Pacific Standard Time', territory: 'PG', type: 'Pacific/Bougainville'},
    {mapZone: 'Central Pacific Standard Time', territory: 'SB', type: 'Pacific/Guadalcanal'},
    {mapZone: 'Central Pacific Standard Time', territory: 'VU', type: 'Pacific/Efate'},
    {mapZone: 'Central Pacific Standard Time', territory: 'ZZ', type: 'Etc/GMT-11'},
    {mapZone: 'Russia Time Zone 11', territory: '001', type: 'Asia/Kamchatka'},
    {mapZone: 'Russia Time Zone 11', territory: 'RU', type: 'Asia/Kamchatka Asia/Anadyr'},
    {mapZone: 'New Zealand Standard Time', territory: '001', type: 'Pacific/Auckland'},
    {mapZone: 'New Zealand Standard Time', territory: 'AQ', type: 'Antarctica/McMurdo'},
    {mapZone: 'New Zealand Standard Time', territory: 'NZ', type: 'Pacific/Auckland'},
    {mapZone: 'UTC+12', territory: '001', type: 'Etc/GMT-12'},
    {mapZone: 'UTC+12', territory: 'KI', type: 'Pacific/Tarawa'},
    {mapZone: 'UTC+12', territory: 'MH', type: 'Pacific/Majuro Pacific/Kwajalein'},
    {mapZone: 'UTC+12', territory: 'NR', type: 'Pacific/Nauru'},
    {mapZone: 'UTC+12', territory: 'TV', type: 'Pacific/Funafuti'},
    {mapZone: 'UTC+12', territory: 'UM', type: 'Pacific/Wake'},
    {mapZone: 'UTC+12', territory: 'WF', type: 'Pacific/Wallis'},
    {mapZone: 'UTC+12', territory: 'ZZ', type: 'Etc/GMT-12'},
    {mapZone: 'Fiji Standard Time', territory: '001', type: 'Pacific/Fiji'},
    {mapZone: 'Fiji Standard Time', territory: 'FJ', type: 'Pacific/Fiji'},
    {mapZone: 'Tonga Standard Time', territory: '001', type: 'Pacific/Tongatapu'},
    {mapZone: 'Tonga Standard Time', territory: 'KI', type: 'Pacific/Enderbury'},
    {mapZone: 'Tonga Standard Time', territory: 'TK', type: 'Pacific/Fakaofo'},
    {mapZone: 'Tonga Standard Time', territory: 'TO', type: 'Pacific/Tongatapu'},
    {mapZone: 'Tonga Standard Time', territory: 'ZZ', type: 'Etc/GMT-13'},
    {mapZone: 'Samoa Standard Time', territory: '001', type: 'Pacific/Apia'},
    {mapZone: 'Samoa Standard Time', territory: 'WS', type: 'Pacific/Apia'},
    {mapZone: 'Line Islands Standard Time', territory: '001', type: 'Pacific/Kiritimati'},
    {mapZone: 'Line Islands Standard Time', territory: 'KI', type: 'Pacific/Kiritimati'},
    {mapZone: 'Line Islands Standard Time', territory: 'ZZ', type: 'Etc/GMT-14'},
];
  
