import axios from 'axios';
import React, {useState} from 'react';
import styled from 'styled-components';
import {Popup, TextField} from '@partssourceinc/react-ui-core';

const Container = styled.div`

`;

const Title = styled.div`
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 300;
`;

const Message = styled.div`
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 28px;
`;

const TextFieldContainer = styled(TextField)`
    width: 189px;
`;

export function AltSourcePoEntryModal({onSubmit, onClose, option}) {  
    const [poNumber, setPoNumber] = useState(option.altPoNumber);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
 
    const onSubmitPo = () => {
        if (poNumber && poNumber.match(/^[A-Za-z0-9\-\s]{1,61}$/)) {
            setIsLoading(true);
            let request = _.merge(option, {altPoNumber: poNumber});
            axios.post('/ShoppingService/api/v1.0/orders/alt/accept', request).then(() => {
                setIsSubmitted(true);
                setIsLoading(false);
                onSubmit();
            });        
        } else {
            setShowErrors(true);
        }
    }

    return (
        <Popup 
            className="alt-source-po-modal"
            confirmText={isSubmitted ? 'Done' : 'Submit'}             
            show={true} 
            hideButtons={false}
            hideCancel={isSubmitted}
            onConfirm={isSubmitted ? onClose : onSubmitPo}
            loading={isLoading}
            onCancel={onClose}>
            {!isSubmitted ? <Container>
                <Title>New PO # Request</Title>
                <Message>If we are able to get your alternate item, we will require a new PO #. Please provide below:</Message>
                <TextFieldContainer id="po-entry" label="PO #" showErrorMessage={showErrors && !poNumber} tabIndex={0} required={true}
                    placeholder="PO #" text={poNumber} onChange={(e) => setPoNumber(e.target.value)} />
            </Container> : 
                <Container>
                    <Title>PO # Submitted</Title>
                    <Message>The new PO # has been been successfully submitted.</Message>
                </Container>
            }
        </Popup>
    );
}
