import axios from 'axios';
import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {actionCreators as cartActions} from 'stores/Cart'
import {Dropdown} from '@partssourceinc/react-ui-core';
import Shipment from '../pages/Checkout/components/Shipment';

import 'less/shippingMethodEdit.less';

export default function ShippingMethodEdit(props) { 
    const {onLoading, onFinishLoading, lineItemIds, onSave} = props;
    const dispatch = useDispatch();
    const {saveCheckout, resetCheckout, getGroupedShippingOptions, loadFacilityAddresses, loadFacilityCheckoutSettings} = cartActions;
    const user = useSelector(state => state.user);
    const {facilities} = user;
    const checkout = useSelector(state => state.cart.checkout);
    const {shipments, shippingAddress, shippingAddresses} = checkout;
    
    const hasOpenShipment = shipments.some(s => s.open);
    
    const [loading, setLoading] = useState(true);
    const [retrievingShippingOptions, setRetrievingShippingOptions] = useState(false);

    useEffect(() => {        
        setRetrievingShippingOptions(true);
        dispatch(resetCheckout());

        axios.post('/ShoppingService/api/v1/cart/approval/checkout/items', lineItemIds).then(response => {
            let d = response.data;
       
            let updatedCheckout = checkout;

            d.checkout.shipments.forEach(s => {                                
                s.selectedShipOption = s.shipOption;
                s.shipOptions = [];
                s.shippingMethod = {
                    carrier: '',
                    carrierId: s.shipOption.carrierId,
                    shipAccountNo: s.shipAccountNo,
                    useCustomerAccount: s.useCustomerShippingAccount,
                    shippingInsurance: s.includeTransitCoverage,
                    selectedPriorityId: s.shipPriorityId,
                    isGsa: s.isGsa,
                };
                s.open = false;
                s.complete = true;
            });
            
            updatedCheckout.isApprovalOrder = true;
            updatedCheckout.items = d.checkout.cartItems;
            updatedCheckout.facility = facilities.filter(x => Number(x.facilityId) === d.facilityId)[0];
            updatedCheckout.shippingAddress = d.checkout.shippingAddress;
            updatedCheckout.billingAddress = d.checkout.billingAddress;
            updatedCheckout.shippingLocation = {
                attentionTo: d.checkout.attentionTo,
                includePo: d.checkout.includePo,
            };
            updatedCheckout.shipments = d.checkout.shipments;
            updatedCheckout.shipOptions = [
                d.shipOption,
            ];                                    

            dispatch(saveCheckout(updatedCheckout));

            let facilityId = d.facilityId;

            Promise.all([dispatch(loadFacilityCheckoutSettings(facilityId)), dispatch(loadFacilityAddresses(facilityId))]).then((x) => {
                const facilitySettings = x[0].response.data;
                const companyAddresses = x[1].response.data;

                updatedCheckout.facilitySettings = facilitySettings;
                updatedCheckout.shippingAddresses = companyAddresses.shippingAddresses;
                
                dispatch(saveCheckout(updatedCheckout));

                dispatch(getGroupedShippingOptions(true)).then(ws => {
                    setRetrievingShippingOptions(false);
                    setLoading(false);
                    onFinishLoading();                 
                });
            });
        });
    }, [])
    
    const onConfirm = () => {
        setLoading(true);
        onLoading();
        let checkoutShipments = checkout.shipments.map(x => {
            return {
                ShipCost: x.selectedShipOption.netShippingCharge,
                IsGsa: x.shippingMethod.isGsa,
                ShipAccountNo: x.shippingMethod.shipAccountNo,
                IncludeTransitCoverage: x.shippingMethod.shippingInsurance,
                UseCustomerShippingAccount: x.shippingMethod.useCustomerAccount,
                ShipOption: x.selectedShipOption,
                LineItemIds: x.lineItemIds,
            }
        });

        let checkoutData = {
            CartItems: checkout.items,
            ShippingAddress: checkout.shippingAddress,
            BillingAddress: checkout.billingAddress,                                                            
            IncludePo: checkout.shippingLocation.includePo,
            AttentionTo: checkout.shippingLocation.attentionTo,
            Shipments: checkoutShipments,
        };

        let request = {
            checkout: checkoutData,
        };

        axios.post('/ShoppingService/api/v1/approval/shipping', request).then(() => {
            onSave();
            setLoading(false);
        });
    }

    const handleShippingAddressChange = (event, data) => {
        let shipAddress = data.options.filter(x => x.addressId === parseInt(event.target.value))[0] || {};
        checkout.shippingAddress = shipAddress;
        saveCheckout(checkout);
        reloadShipOptions();
    }

    const reloadShipOptions = () => {
        setLoading(true);
        onLoading();
        setRetrievingShippingOptions(true);
        dispatch(getGroupedShippingOptions()).then(ws => {
            setRetrievingShippingOptions(false);
            setLoading(false);
            onFinishLoading();
        });
    }

    const onShipmentContinue = (shipment) => {         
        let s = shipments.find(x => x.id === shipment.id);
        s.complete = true;
        s.open = false;
        
        let pendingShipment = shipments.find(x => !x.complete);
        if (pendingShipment != null) {
            pendingShipment.open = true;
            dispatch(saveCheckout(checkout));
        } else {            
            dispatch(saveCheckout(checkout));
        }
        onConfirm();
    }

    const onEdit = (shipment) => {
        let s = shipments.find(x => x.id === shipment.id);
        s.complete = false;
        s.open = true;

        dispatch(saveCheckout(checkout));          
    }

    return (<div className="ship-method-edit">
        {!loading && <div>
            <Dropdown
                id="shipAddress"
                name="shipAddress"
                label={'Shipping Address'}
                valueField="addressId"
                textField="description"
                showErrorMessage={!shippingAddress.addressId}
                options={shippingAddresses}
                selectedValue={shippingAddress.addressId}
                onChange={handleShippingAddressChange}
                suppressBlankOption={true} />
            <div className="shipping-method">
                <div className="checkout-section">
                    {checkout.shipments.map((x,i) => 
                        (<React.Fragment key={x.id} >
                            <Shipment
                                index={i} shipmentId={x.id} shippingMethodView={{Open: true}} onContinue={onShipmentContinue}
                                retrievingShippingOptions={retrievingShippingOptions} hideBackToCart={true} />
                            {!hasOpenShipment && <div onClick={() => onEdit(x)} className="checkout-section-edit">Edit</div>}
                        </React.Fragment>)
                    )}    
                </div>
            </div>
        </div>}
    </div>)
}
