import axios from 'axios';
import moment from 'moment';
import * as React from 'react';
import {connect} from 'react-redux';
import QueryString from 'query-string';
import {useLocation, withRouter} from 'react-router';
import * as ReactRouterPropTypes from 'react-router-prop-types';
import _ from 'lodash';
import { Button, Popup, ProductImage, QuantitySelector } from '@partssourceinc/react-ui-core';
import ApprovalPurchaseButton from 'components/ApprovalPurchaseButton';
import {AssetFormType} from 'components/AssetInformationForm';
import BuyingOptionsDialog from 'components/BuyingOptionsDialog';
import InfoCenterMain from 'components/InfoCenter/InfoCenterMain';
import PageMetaData from 'components/PageMetaData';
import PDFButton from 'components/PDFButton';
import RejectApprovalDialog from 'components/RejectApprovalDialog';
import InitiateReturnModal from 'components/InitiateReturnModal';
import SalesQuote from 'components/SalesQuote';
import ShippingMethodEdit from 'components/ShippingMethodEdit';
import TrackingNumberEntryDialog from 'components/TrackingNumberEntryDialog';
import TrackOrderSMSDialog from 'components/TrackOrderSMSDialog';
import LoanerAcceptanceDialog from 'components/LoanerAcceptanceDialog';
import TrackingPanel from 'components/TrackingPanel';
import {AltSourcePoEntryModal} from 'components/AltSourcePoEntryModal';
import * as CartStore from 'stores/Cart';
import * as UserStore from 'stores/User';
import * as NetworkStore from 'stores/Network';
import * as ParamsStore from 'stores/Params';
import * as CompaniesStore from 'stores/Companies';
import {debounce, formatMoney, getCookie, logEvent, logEventVerbose, getPODLetter, downloadCreditMemos} from 'utility';
import {Link} from 'react-router-dom';
import {Lg, Sm, Md, Xs} from 'responsive';
import {getVendorAttribute} from 'productUtility';

import 'less/orderDetail.less';
import 'less/settings/orderLineItem.less';
import {BrComponent} from '@bloomreach/react-sdk';
import {RETURN_CONFIRMATION_MSG, OrderLineItemStatus} from '../enums'
import { data } from 'jquery';
import { classNames } from '../utility';
import styled from 'styled-components';

const CardMissingPaperwork = styled.div`
background-color: #f9f9f9;
padding: 25px 15px;

  > .card-missing-paperwork-title {
    font-weight: 600;
    margin-bottom: 5px;
  }
  
  > .card-rga-printed {
    margin-bottom: 5px;

    span {
    }
  }
`;

@withRouter
@connect(
    (state) => ({user: state.user, network: state.network, params: state.params, companies: state.companies, dashboard: state.dashboard, bloomreach: state.bloomReach, sessionStorageAvailable: state.network.sessionStorageAvailable, checkout: state.cart.checkout}),
    _.merge({}, CartStore.actionCreators, UserStore.actionCreators, CompaniesStore.actionCreators)
)

export default class OrderDetail extends React.Component {
    static propTypes = {
        ...ReactRouterPropTypes,
        ...CartStore.ActionShape,
        ...UserStore.ActionShape,
        ...CompaniesStore.ActionShape,
        user: UserStore.StateShape,
        network: NetworkStore.StateShape,
        params: ParamsStore.StateShape,
        companies: CompaniesStore.StateShape,
        
    };

    constructor(props) {
        super(props);

        this.state = {
            orderLines: [],
            actionableLines: [],
            showBuyingOptions: false,
            buyingOptions: [],
            buyingOptionsImage: '',
            showRejectDialog: false,
            rejectItems: [],
            facility: {},
            saving: false,
            showTrackingPanel: null,
            showCheckInDialog: false,
            showReturnDialog: false,
            selectedLineItem: {},
            showTrackingNumberDialog: false,
            showInfoCenterDialog: false,
            conditionNotes: [],
            infoCenterTab: '',
            currentlyEditingOrderLineId: null,
            loadingShippingOptionsByLineId: {},
            updatingQuantity: false,
            showMobileShippingModal: false,
            showNudgeConfirmDialog: false,
            showNudgeRequesterConfirmDialog: false,
            approvalGroupName: '',
            isHoldForPo: false,
            showConfirmation: false,
            hasActionButtons: false,
            creatingLabels: false,
            hideTrackOrderSMSPrompt: false,
            showSmsPrompt: false,
            showNudgeRequester: false,
            autoScrollLineItemId: null,
            altPoNumber: undefined,
            altAcceptBusy: false,
            altRejectBusy: false,
            altBusyVendorResearchId: 0,
            showAltPoModal: false,
            altPoOption: {},
            showMissingInvoice: false,
            showLoanerAcceptanceDialog: false,
            loanerItem: null,
            loanerStatusAssociated: '',
            qtyFocused: false,
            showReturnConfirmation: false,
        }

        this.autoScrollRef = React.createRef();
    }

    componentWillMount() {
        const {location: {hash, pathname}, match: {params: {lineItemId}}} = this.props;
        if (hash && hash.replace('#', '')) {
            this.setState({autoScrollLineItemId: lineItemId});
            window.history.replaceState(null, null, pathname);
        }
    }

    componentDidMount() {
        const {
            match: {params: {lineItemId}},
            history,
            companies: {oneSourceMappings},
            getOneSourceOemMappings,
            bloomreach: {isPreview},
            resetCheckout,
        } = this.props;
        let {loadFacilityCheckoutSettings} = this.props;
        const parsed = QueryString.parse(window.location.search);

        if (isPreview) {
            return;
        }
        resetCheckout();
        let liId = lineItemId;
        
        if (liId === undefined && parsed.orderId) {
            axios.get(`/ShoppingService/api/v1/orders/orderlines/${parsed.orderId}`).then(x => {
                if (x.data.length > 0) liId = x.data[0];
                this.getData(null, liId);
            });
        } else {
            this.getData();
        }

        if (oneSourceMappings.length === 0) {
            getOneSourceOemMappings();
        }
       
    }

    getFieldValues = (values, formType, requester, isUrgency) => {
        const {user: {info: {contactId}}} = this.props;
        const {selectedLineItem} = this.state;
        
        axios.all([
            this.saveFields(values, contactId),
            this.saveRequester(requester),
            this.saveUrgency(selectedLineItem.lineItemId, isUrgency)]).then(() => {
            this.getData();
        });
        this.setState({showAssetInformationPopup: false});
    }

    saveUrgency(lineItemId, priority) {
        this.updateSelectedLineItemPriority(priority);
        return axios.post(`/ShoppingService/api/v1.0/cart/updatePriority/${lineItemId}/${priority}`);
    }

    updateSelectedLineItemPriority = (newPriority) => {
        const {selectedLineItem} = this.state;
        this.setState({
            selectedLineItem: {
                ...selectedLineItem,
                priority: newPriority,
            },
        });
    }

    saveFields = (fields, contactId) => axios.post(`/ShoppingService/api/v1.0/cart/saveFields/${contactId}`, fields)

    saveRequester = (requester) => {
        if (requester && requester.contactId) {
            let {orderLines} = this.state;
            return axios.post('/ShoppingService/api/v1.0/cart/updateRequester', {
                OrderId: orderLines[0].orderId,
                UserId: requester.contactId,
            });
        } else {
            return new Promise((resolve) => {
                resolve();
            });
        }
    }

    getData = (allowQuotes, liId) => {
        const {match: {params: {lineItemId}}, history, submitForPo} = this.props;

        if (liId === undefined) liId = lineItemId;
        
        axios.get(`/ShoppingService/api/v1/orders/detail/${liId}`).then(x => {

            // Safety check for eQuotes
            if (x.data.length === 1 && !allowQuotes) {
                let li = x.data[0];
                let isBackOrderedStatus = li.status.includes('Back Ordered') || li.status.includes('Backorder');
                
                if (li.statusId === 25 && !isBackOrderedStatus) li.status = 'Ordered';
                if (li.statusId === 25 && li.dateReceived) li.status = 'Delivered';
                if (li.statusId === 26 && (!li.extStatusId || !(li.extStatusId === 28 || li.extStatusId === 30)))
                    history.replace(`/quotes/${li.lineItemId}`);
            }

            let tempShowSmsPrompt = this.checkSMSTextToShow(x.data);

            if (x.data && x.data.length >= 1) {
                let facilityId = x.data[0].facilityId;
                if (facilityId > 0 && submitForPo == undefined)
                    this.loadCompanySettings(facilityId);
            }

            let showNudgeRequester = false;

            if (history.location.state && (history.location.state.cardActionType == 'Exchange' || history.location.state.cardActionType == 'Return'))
                if ((moment().isAfter(moment(history.location.state.rgaEtaDate), 'day') || moment().isAfter(moment(history.location.state.rgaEtaDate).subtract(2, 'days'), 'day')))
                    showNudgeRequester = true;

            const urlParams = new URLSearchParams(history.location.search);
            const isInitiateReturn = urlParams.get('initiate_return');
            const initializedLineItem = x.data.find(d => d.lineItemId === parseInt(liId))

            this.setState({
                orderLines: x.data,
                actionableLines: x.data.filter(x => !x.hasApproved && x.canApprove),
                showBuyingOptions: false,
                buyingOptions: [],
                showRejectDialog: false,
                rejectItems: [],
                saving: false,
                showReturnDialog: isInitiateReturn ?? false,
                showCheckInDialog: false,
                showInfoCenterDialog: false,
                conditionNotes: [],
                showTrackingNumberDialog: false,
                selectedLineItem: initializedLineItem ?? {},
                currentlyEditingOrderLineId: null,
                loadingShippingOptionsByLineId: {},
                updatingQuantity: false,
                showNudgeConfirmDialog: false,
                showNudgeRequesterConfirmDialog: false,
                approvalGroupName: (history.location.state) ? history.location.state.nextApprovalGroup : '',
                isHoldForPo: (history.location.state) ? history.location.state.isHoldForPo : false,
                creatingLabels: false,
                showSmsPrompt: tempShowSmsPrompt,
                showNudgeRequester: showNudgeRequester,
                altAcceptBusy: false,
                altRejectBusy: false,
                altBusyVendorResearchId: 0,
                showOnlyConditionNotes: false,
            });

        });
    }

    onApprove = (item, approveAll) => {        
        const {actionableLines} = this.state;
        const {user: {facilities}} = this.props;

        let request;

        if (approveAll) {
            const facility = facilities.filter(x => x.facilityId == actionableLines[0].facilityId)[0];
            request = {
                groupId: facility.groupId,
                companyId: facility.facilityId,
                lineItemIds: actionableLines.map(x => x.lineItemId),
            };
        } else {
            const facility = facilities.filter(x => x.facilityId == item.facilityId)[0];
            request = {
                groupId: facility.groupId,
                companyId: item.facilityId,
                lineItemIds: [item.lineItemId],
            };
        }

        this.setState({saving: true});

        axios.post('/ShoppingService/api/v1/approval/approve', request).then(() => {
            this.getData();
        });
    }

    onNudgeApprover = (item, isHoldPo) => {
        const {user: {facilities}, location} = this.props;

        let request;
        const facility = _.find(facilities, (x => x.facilityId == item.facilityId));
        request = {
            groupId: facility.groupId,
            companyId: item.facilityId,
            lineItemIds: [item.lineItemId],
            isPoHold: isHoldPo,
        };
        axios.post('/ShoppingService/api/v1/orders/sendNudge', request).then(() => {
            this.setState({showNudgeConfirmDialog: true, selectedLineItem: item});
        });
        const id_ins = getCookie('id_ins');

        let logDataDetails = [];
        let currdate = new Date();
        let createdate = moment(item.dateCreated).toDate();
        logDataDetails.push({name: 'id_ins', value: id_ins});
        logDataDetails.push({name: 'Nudge Approver', value: item.lineItemId});
        logDataDetails.push({name: 'Ship Priority', value: item.shippingPriority});
        logDataDetails.push({name: 'Age', value: `${parseInt((currdate - createdate) / (1000 * 60 * 60 * 24))}day `});
        if (location.state.shipCutoffUtc) {
            let cuttoff = moment(location.state.shipCutoffUtc).toDate();
            logDataDetails.push({name: ' Time to Cutoff', value: `${parseInt((cuttoff - currdate) / (1000 * 60))}min`});
        }
        logEventVerbose('Nudge Approver', logDataDetails);
    }

    onNudgeRequester = (item) => {
        const {history} = this.props;

        let type = history.location.state.cardActionType == 'Exchange' ? 1 : 2;

        axios.post(`/ShoppingService/api/v1/orders/${item.lineItemId}/sendNudgeRequester/${type}`, {}).then(() => {
            this.setState({showNudgeRequesterConfirmDialog: true, selectedLineItem: item});
        });
        // const id_ins = getCookie('id_ins');

        // let logDataDetails = [];
        // let currdate = new Date();
        // let createdate = moment(item.dateCreated).toDate();
        // logDataDetails.push({ name: 'id_ins', value: id_ins });
        // logDataDetails.push({ name: 'Nudge Approver', value: item.lineItemId });
        // logDataDetails.push({ name: 'Ship Priority', value: item.shippingPriority });
        // logDataDetails.push({ name: 'Age', value: `${parseInt((currdate - createdate) / (1000 * 60 * 60 * 24))}day ` });
        // if (location.state.shipCutoffUtc) {
        //     let cuttoff = moment(location.state.shipCutoffUtc).toDate();
        //     logDataDetails.push({ name: ' Time to Cutoff', value: `${parseInt((cuttoff - currdate) / (1000 * 60))}min` });
        // }
        // logEventVerbose('Nudge Approver', logDataDetails);
    }

     handleOnCheckInConfirm = () => {
        const {selectedLineItem} = this.state;
        this.setState({showCheckInDialog: false});
        const {updatePartReceived, user: {info}} = this.props;
        this.setState({updatingQuantity: true});
        updatePartReceived(selectedLineItem).then(() => {             
            let logObj = {
                'Reference Number': selectedLineItem.lineItemId,
                'User ID': info.contactId,
                'Originating Status': selectedLineItem.statusId === 25 
                    ? 'Tab Ordered' 
                    : selectedLineItem.statusId === 8 && selectedLineItem.status === 'Shipped'
                        ? 'Tab Shipped' 
                        : 'Tab Delivered'
            }    
            logEvent('Check In Button', logObj); 
            this.getData();
            this.setState({updatingQuantity: false});
        });
        if (selectedLineItem.statusId === 25) {            
            axios.post('/ShoppingService/api/v1/lineItems/receivedPost', selectedLineItem.lineItemId).then(response => {
            });
        }
    }

    associateTMSOrder = (lineItemId) => {
        const {history, params} = this.props;

        let test = {
            CustomerOrderKey: params.wo_id,
            WorkOrderNum: params.wo,
            SerialNum: params.serial,
            AssetId: params.asset,
            LineItemId: lineItemId,
        };
        axios.post('/ShoppingService/api/v1/lineitems/associateOrder', test).then(response => {

            history.push('/orders/history');

        }).catch(() => this.setState({isBusy: false}));
    }

    renderRgaSection(rga, item) {
        const {orderLines} = this.state;
        const dueDate = moment(rga.rgaDueDate).format('dddd MMM DD, YYYY');
        const createdDate = moment(rga.rgaDateEntered).format('MM/DD/YYYY LT');
        const isReturnOrExchange = rga.rgaType.toLowerCase() === 'return' || rga.rgaType.toLowerCase() === 'exchange';
        const canGenerateShippingLabel = rga.canGenerateShippingLabel && item.statusId !== 12;
        return (
            <div className="line-item-summary">
                <div className="item-row">
                    <div className="rga-section rga-section-exchange">
                        <div className="product-column" style={{marginLeft: 0}}>
                            <div className="label-two">{rga.isOverDue ? `${rga.rgaType} Past Due ${dueDate}` : `${rga.rgaType} Due ${dueDate}`}</div>
                            {rga.isOverDue ? <div className="due-icon overDue" style={{color: 'red'}}><i className="fa fa-exclamation overDue" />Past Due</div> : null}
                            {rga.isDue ? <div className="due-icon isDue"><i className="fa fa-exclamation isDue" />Due in 5 days or less</div> : null}
                            {(rga.rgaDateEntered &&
                                     !canGenerateShippingLabel
                                      && (!rga.vendorRmaRequired || (rga.vendorRmaRequired && rga.vendorRmaNumber))
                                      && (item.vendorTrackingNumber && item.isShipFromCustomer)) ? <div className="createdDate"><img src={`/images/icn_checkmark.png`} style={{width: 24, height: 24}} /><span>Label Created</span> on {createdDate} </div> : null}
                            {this.renderRgaDetailsBasedOnType(rga)}
                        </div>
                        <div className="action-column">
                            <div className="action-row" style={{color: '#2C3A47 !important'}}>
                                <div className="action-item" >
                                    {isReturnOrExchange ?
                                        rga.rgaNumber ? (
                                            <PDFButton
                                                className="print-label-and-docs"
                                                buttonType={'Link'}
                                                title="PRINT FORM"
                                                fileName={rga.rgaNumber}
                                                pdfUrl={`ShoppingService/api/v1.0/lineItems/rgapaperwork/${rga.lineItemId}/${rga.rgaNumber}`}
                                            />
                                        ) : null
                                        :
                                        <React.Fragment>
                                            {canGenerateShippingLabel && <Button onClick={() => this.onPackingBundle(rga.lineItemId)} secondary={true}>PRINT FORM</Button>}
                                            {(!canGenerateShippingLabel &&
                                                (!rga.vendorRmaRequired || (rga.vendorRmaRequired && rga.vendorRmaNumber)) &&
                                                (item.vendorTrackingNumber && item.isShipFromCustomer)) ?
                                                <PDFButton
                                                    title="Print Form"
                                                    buttonType="Secondary"
                                                    directLink={true}
                                                    fileName={`PackingSlip${rga.lineItemId}`}
                                                    pdfUrl={`${process.env.REACT_APP_ORION_API}/ReportGenService/RgaReports/RgaPackingSlip?lineItemId=${rga.lineItemId}`} /> : null
                                            }
                                        </React.Fragment>
                                    }
                                </div>
                                <div className="action-item" onClick={() => this.onClickShowTrackingNumberDialog(rga)}>ENTER TRACKING NUMBER</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
    }

    renderRgaDetailsBasedOnType = (rga) => {
        if (rga.rgaType.toLowerCase() === 'repair')
            return <div className="return-reason"><span>To avoid cancellation ship your repair to us by the due date. If you have used your own shipping label enter tracking to the right to avoid delays.</span></div>
        else if (rga.rgaType.toLowerCase() === 'exchange')
            return <div className="return-reason"><span>To avoid additional charges, your core exchange must be shipped back by the due date.  If you have used your own shipping label enter tracking below to avoid delays.</span></div>
        else if (rga.rgaType.toLowerCase() === 'return')
            return <div className="return-reason"><span>Returns are credited upon receipt and inspection. If you have used your own shipping label enter tracking below to avoid delays.</span></div>
        return null;
    }

    onClickShowTrackingNumberDialog =(rga) => {
        let logObj = {
            'Reference Number': rga.lineItemId,
            'Origination': rga.rgaType + ' detail page',
        }

        logEvent('Tracking Entry', logObj);

        this.setState({showTrackingNumberDialog: true, selectedLineItem: rga});
    }

    renderEmptyRgaSection = (item) => {
        const {loanerStatusAssociated} = this.state
        if (item.orderType != 'REPAIR') return null;
        return (
            <div className="line-item-summary">
                <div className="item-row">
                    <div className="rga-section">
                        <div className="cart-product-image" />
                        <div className="product-column" style={{ marginLeft: 0 }}>
                            {(item.rgaStatusId == 0 && sessionStorage.prevGrid == 'Repairs') || (sessionStorage.prevGrid == 'Loaners' && loanerStatusAssociated === 'Requested') &&
                                <>
                                    <div className="label-two" style={{fontWeight: 'bold'}}>Repair Initiated</div>                            
                                    <div className="return-reason"><span style={{fontWeight: 600}}>Paperwork can take up to 48 hours to prepare. Sending without this paperwork can cause additional delays.</span></div>
                                </>
                            }
                            {loanerStatusAssociated === 'Quoted' || loanerStatusAssociated === 'ShippedOutbound' &&
                                <>
                                <div className="label-two" style={{ fontWeight: 'bold' }}>Repair Due</div>
                                    <div className="label-two" style={{fontWeight: 'bold'}}>Paperwork Available on MM/DD</div>
                                    <div className="return-reason"><span style={{fontWeight: 600}}>Paperwork can take up to 48 hours to prepare. Sending without this paperwork can cause additional delays.</span></div>
                                </>
                            }
                        </div>
                        <div className="quantity-column" />
                        <div className="price-column">&nbsp;</div>
                        <div className="extPrice-column">&nbsp;</div>
                        {loanerStatusAssociated === 'Quoted' || loanerStatusAssociated === 'ShippedOutbound' &&
                            <div className="action-column">
                                <div className="action-item">
                                    <div className="loaner" onClick={() => this.onPackingBundle(item.lineItemId)}>PRINT FORM</div>
                                    <div className="loaner" onClick={() => this.onClickShowTrackingNumberDialog(item)}>ENTER TRACKING NUMBER</div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>)
    }

    getLoanerStatusAssociated(item) {
        if (typeof item.loanerStatus !== 'undefined') {
            this.setState({ loanerStatusAssociated: item.loanerStatus })
        }
    }

    renderEmptyRgaSectionForLoaners = (item) => {
        if (item.orderType === 'REPAIR' && sessionStorage.prevGrid === 'Loaners')
            return (
                <div className="line-item-summary">
                    <div className="item-row">
                        <div className="rga-section">
                            <div className="cart-product-image" />
                            <div className="product-column" style={{marginLeft: 0}}>
                                {item.rgaStatusId === 0 && 
                                    <>
                                        <div className="label-two" style={{fontWeight: 'bold'}}>Repair Initiated</div>                            
                                        <div className="return-reason"><span style={{fontWeight: 600}}>Paperwork can take up to 48 hours to prepare. Sending without this paperwork can cause additional delays.</span></div>
                                    </>
                                }
                                {item.rgaStatusId !== 0 && 
                                    <>
                                        <div className="label-two" style={{ fontWeight: 'bold' }}>Repair Due</div>
                                        <div className="label-two" style={{fontWeight: 'bold'}}>Paperwork Available on MM/DD</div>
                                        <div className="return-reason"><span style={{fontWeight: 600}}>Paperwork can take up to 48 hours to prepare. Sending without this paperwork can cause additional delays.</span></div>
                                    </>
                                }
                            </div>
                            <div className="quantity-column" />
                            <div className="price-column">&nbsp;</div>
                            <div className="extPrice-column">&nbsp;</div>
                            {item.rgaStatusId > 0 && 
                                <div className="action-column">
                                    <div className="action-item">
                                        <div className="loaner" onClick={() => this.onPackingBundle(item.lineItemId)}>PRINT FORM</div>
                                        <div className="loaner" onClick={() => this.onClickShowTrackingNumberDialog(item)}>ENTER TRACKING NUMBER</div>
                                    </div>
                                </div>
                            }
                        </div>                   
                    </div>                
                </div>)
    }

    renderLoanerRequestedOrQuoted = (item) => {
        return item.loanerStatus === 'Requested' || item.loanerStatus === 'Quoted' ? 
            (
                <div className="line-item-summary">
                    <div className="item-row">
                        <div className="rga-section">
                            <div className="cart-product-image" />
                            <div className="product-column" style={{marginLeft: 0}}>
                                {item.loanerStatus === 'Requested' &&
                                    <>
                                        <div className="label-two" style={{fontWeight: 'bold'}}>Loaner Requested</div>
                                        <div className="return-reason"><span style={{fontWeight: 600}}>Loaners availability can take up to 24 hours to confirm.
                                        Once your requested loaner is confirmed you must accept your loaner and our <a style={{color: '#005da6'}}
                                            href="https://www.partssource.com/policies/termsandconditionofsale">Terms and Conditions</a>.</span>
                                        </div>
                                    </>
                                }
                                {item.loanerStatus === 'Quoted' &&
                                    <>
                                        <div className="label-two" style={{fontWeight: 'bold'}}>Accept Loaner</div>
                                        <div className="label-two" style={{fontWeight: 'bold'}}>Loaner Available on MM/DD</div>
                                        <div className="return-reason"><span style={{fontWeight: 600}}>Accept your loaner unit and our <a style={{color: '#005da6'}}
                                            href="https://www.partssource.com/policies/termsandconditionofsale">Terms and Conditions</a> within 24 hours. Loaners accepted beyond 24 hours can cause additional delays.</span>
                                        </div>
                                    </>
                                }
                                
                            </div>
                            <div className="quantity-column" />
                            <div className="price-column">&nbsp;</div>
                            <div className="extPrice-column">&nbsp;</div>
                            {/*These loaner Accept and Reject buttons will implemented later. See AP-10120 comments for dev notes*/}
                            {/*{item.loanerStatus == 'Quoted' &&*/}
                            {/*<div className="action-column">*/}
                            {/*    <div className="action-item">*/}
                            {/*        <div className="loaner" onClick={() => this.acceptLoanerDialog(item)}>ACKNOWLEDGE & ACCEPT LOANER UNITT</div>*/}
                            {/*        <div className="loaner" onClick={() => this.rejectLoaner(item)}>REJECT LOANER</div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*}*/}
                        </div>
                    </div>
                </div>) : null;
    }

    renderMissingInvoice = () => {
        return (
            <Popup show={true} hideButtons={true} onCancel={() => this.setState({showMissingInvoice: false})}>
                <div>This invoice is not currently available.</div>
            </Popup>
        );
    }

    scrollToAltSourceLine = () => {        
        if (this.autoScrollRef && this.autoScrollRef.current) {            
            this.autoScrollRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
            this.setState({autoScrollLineItemId: null});
        }
    };

    onShowPodLetter = (lineItemId) => {
        getPODLetter(lineItemId, this.props.network.tokenInfo, "OrderDetail").then(x => {
            if (!x) this.setState({showMissingInvoice: true});
        });   
    }

    renderOrderLineItem = (item, hasActionButtons, facility) => {
        const {isContractProApproval, imagePath, imageAlt, title, orderType} = item;
        const {actionableLines, updatingQuantity, autoScrollLineItemId, qtyFocused,
            currentlyEditingOrderLineId, loadingShippingOptionsByLineId} = this.state;
        const {user: {facilities, settings}, companies: {oneSourceMappings}, history, location} = this.props;
        const allowQtyEdit = actionableLines.length > 0;
        const hidePricing = settings.hidePricing;
        const showBackorderMessages = settings.isShowBackorderMessages;
        const selectedOption = item.options.find(o => o.isSelected);
        let unitPrice = item.orderType === 'REPAIR' && (item.statusId === OrderLineItemStatus.Requested || item.statusId === OrderLineItemStatus.Sourcing || (item.statusId === OrderLineItemStatus.Sourced && !(selectedOption ? selectedOption.price : null)) || item.statusId === OrderLineItemStatus.Repairing) ? 'Pending Evaluation' : item.unitPrice || (selectedOption ? selectedOption.price : null);
        let extPrice = unitPrice == 'Pending Evaluation' ? 'Pending Evaluation' : unitPrice * item.quantity;
        const isEditingThisOrderLine = currentlyEditingOrderLineId === item.lineItemId;
        const isLoadingShippingOptions = loadingShippingOptionsByLineId[item.lineItemId];
        const statusToShow = item.orderType === 'REPAIR' && item.status === 'Part Declined' && item.statusId === OrderLineItemStatus.NoSale ? 'Repair Declined (No Cost)' : item.status;
        let {detailUrl} = item;

        if (item.isVariantProduct) {
            if (selectedOption) detailUrl = `${detailUrl}?pspn=${selectedOption.psPartNumber}&vrnt=${selectedOption.itemNumber}`;
        }

        const renderQuantity = () =>
            (<div className="qtySelector">
                <QuantitySelector key={`mobile-cart-qty-${item.lineItemId}`} className="quantity-select"
                    style={{marginTop: -10, background: 'white', padding: 0, paddingLeft: 15}}
                    selectedValue={item.quantity}
                    ref={(input) => {
                        this.qtyInput = input 
                    }}
                    onChange={(e) => {
                        this.handleQuantityChange(e.target.value, item);
                        this.setState({qtyFocused: false});
                    }}
                    onFocus={() => this.setState({qtyFocused: true})} 
                    onBlur={() => this.setState({qtyFocused: false})}
                    minQty={selectedOption?.moq || 1} minQtyOptionLabel={selectedOption?.moq ? '(Min. Amount Required)' : ''}
                    disabled={updatingQuantity || item.orderType === 'REPAIR' ? 'disabled' : undefined}
                />
                {!qtyFocused && selectedOption && selectedOption.moq && selectedOption.moq > item.quantity && <span className="min-order-qty-msg">{`This item has a minimum order quantity of ${selectedOption.moq}.`}</span>}
            </div>)

        const renderShipping = () => {

            let esd = 'Pending';

            if (item && (item.status === 'Ordered' || item.status === 'Awaiting Approval') && item.estimatedShipDate)
                esd = moment(item.estimatedShipDate).format('MM/DD');
            else if (item && item.status === 'Shipped')
                if (item.estimatedDeliveryDate)
                    esd = moment(item.estimatedDeliveryDate).format('MM/DD');
                else
                    esd = null;
            else if (item && item.status === 'Delivered')
                esd = null;

            return (<>
                <span>{esd}</span>
            </>)
        }

        const renderMobileFooter = () => (<>

            <div className="required-information-mobile" onClick={() => this.setState({showInfoCenterDialog: true, infoCenterTab: 'Asset', selectedLineItem: item})}>Required &amp; Optional Information</div>
            <div className="mobile-quantity-price mobile-quantity-line">
                {allowQtyEdit ? renderQuantity() : <span className="price-value" style={{fontSize: 16}}>Qty: <strong>{item.quantity}</strong></span>}
                <span className="price" style={{fontSize: 16, marginRight: 20}}><span className="price-value" style={{fontSize: 16}}>{!hidePricing && formatMoney(unitPrice)}</span><span>/{item.uomCode ? item.uomCode.toLowerCase() : ''}</span></span>
                <span className="price"><span className="price-value" style={{fontSize: 27, marginLeft: 0}}>{!hidePricing && formatMoney(extPrice)}</span></span>
            </div>

        </>);

        const renderMobileActionButtons = () => <div className="order-product-actions-space-mobile hidden action-column">{this.renderActionButtons(item)}</div>;

        const renderCerts = (certifications) => {
            return (
                <div>
                    {Object.keys(certifications).map((c, index) => (
                        <span className="cert-wrapper has-tooltip">
                            <img key={`img_cert_${index}`} src={`/images/${getVendorAttribute(c)}`} alt={certifications[c]}/>
                            <span className='tooltip-wrapper'><span className='custom-tooltip'>{certifications[c]}</span></span>
                        </span>
                    ))}
                </div>
            );
        };

        let showWarningMessage = false;
        let warrningMessage = '';
        let showFollowupDate = false;

        if (showBackorderMessages && item.statusId == 25 && (item.estimatedShipDateReasonId == 2 || item.backordered)) { // ordered && backorder
            showWarningMessage = true;
            if (item.estimatedShipDate)
                warrningMessage = `This item is on backorder until ${moment(item.estimatedShipDate).format('MM/DD/YYYY')}`;
            else
                warrningMessage = `This item is on backorder with no estimated ship date`;

            showFollowupDate = true;
        } else if (showBackorderMessages && item.statusId == 25 && (!item.trackingStatusId || item.trackingStatusId == 1) && (item.transitDays == 1 || item.transitDays == 2) && (item.estimatedShipDate && new Date(item.estimatedShipDate) > new Date().setHours(0, 0, 0, 0))) {
            showWarningMessage = true;
            warrningMessage = `This item will ship on ${moment(item.estimatedShipDate).format('MM/DD/YYYY')}`
        }

        let autoScroll = autoScrollLineItemId === item.lineItemId.toString();
        let loanerType = item.loanerType;        

        if (loanerType === 0 && item.options && item.options.length > 0 && item.orderType === 'LOANER') {
            loanerType = item.options[0].loanerType;
            unitPrice = item.options[0].price;
        }

        const renderRgaContent = () => {
            const {prevGrid} = sessionStorage;
            const isLoanersGrid = prevGrid === 'Loaners';
            const hasRgaHeaders = item.rgaHeaders && item.rgaHeaders.length > 0;

            if (!isLoanersGrid && hasRgaHeaders) {
                return item.rgaHeaders.map(r => this.renderRgaSection(r, item));
            }
            else if (isLoanersGrid) {
                return this.renderEmptyRgaSectionForLoaners(item);
            }
            return this.renderEmptyRgaSection(item);
        };

        const renderMissingPaperWorkSection = (item) => {
            const isQuoted = ['aftermarket quoted repair', 'oem quoted repair', 'n/a'].includes(item?.condition?.toLowerCase())

                return (

                    <CardMissingPaperwork>
                        <div className="card-missing-paperwork-title">Repair {isQuoted ? 'Quote' : null} Initiated</div>
                        <div className="card-rga-printed">
                            <span>{isQuoted ?
                                'In order to obtain your quote, this item must be sent to the vendor for evaluation. Paperwork can take up to 48 hours to prepare. Sending without this paperwork can cause additional delays' :
                                'Paperwork can take up to 48 hours to prepare. Sending without this paperwork can cause additional delays'}</span>
                        </div>

                    </CardMissingPaperwork>
                );
        }
  
        const shouldRenderMissingPaperWorkSection = (item) => {
            const canGenerateShippingLabel = item.canGenerateShippingLabel;
            const vendorTrackingNumber = item?.vendorTrackingNumber;
            const isShipFromCustomer = item?.isShipFromCustomer;
            const rga = item?.rgaHeaders.find(x => x.lineItemId === item.lineItemId);
            const repairGroupName = item.repairGroupName?.toLowerCase();
            const rgaNumber = rga?.rgaNumber;

            return repairGroupName === 'initiated' && 
                   (!rgaNumber || 
                    !(!canGenerateShippingLabel && 
                     (!rga.vendorRmaRequired || (rga.vendorRmaRequired && rga.vendorRmaNumber)) && 
                     vendorTrackingNumber && isShipFromCustomer));
        };
        
        const renderBottomSection = (item) => {
            return shouldRenderMissingPaperWorkSection(item) 
                ? renderMissingPaperWorkSection(item) 
                : renderRgaContent();
        };
        
        const regex = /[A-Za-z0-9]/;
        
        return (
            <div>
                <div className="order-detail-header" style={{color: '#2C3A47'}} ref={autoScroll ? this.autoScrollRef : null}>
                    {selectedOption && selectedOption.moq && selectedOption.moq > item.quantity &&
                        <div className="note note--warning">
                            <p className="lbl-info">Please select a min. quantity of {selectedOption.moq}</p>
                        </div>}
                    <span className={`order-facility-header ${!hasActionButtons && !item.orderType == "LOANER" && 'has-no-action-button'}`} style={{color: '#2C3A47'}}>
                        <div style={{minWidth: 250, display: 'flex', width: '100%'}}><span className="facility-name">{statusToShow}</span>  <span className="ref-desktop hidden">(Ref <span className="facility-subtotal">#{item.lineItemId}<span style={{fontWeight: 400}}>)</span></span></span></div>
                    </span>
                    <div className="order-product-lead">
                        {renderShipping()}
                    </div>
                    <div className="order-product-quantity" style={{alignItems: 'baseline'}}>
                        {allowQtyEdit ? renderQuantity() : <span className="order-product-price">{item.quantity}</span>}
                    </div>
                    <div className="order-product-price">
                        <span>{!hidePricing && formatMoney(unitPrice)}</span>
                        {item.orderType == "LOANER" && "*"}
                    </div>

                    <div className="order-product-extPrice">
                        {!hidePricing && formatMoney(extPrice)}
                        {item.orderType == "LOANER" && "*"}
                    </div>

                    {item.loanerStatus && loanerType === 1 &&
                        <div className="order-product-replacementfee">
                            <span style={{fontWeight: 600}}>*Replacement Value</span><br/>
                            You will be charged up to the full replacement value in the case of damage or loss to the loaner unit. Call your Sales Representative to proceed.
                            {this.renderLoanerActionButtons(item)}
                        </div>                            
                    }
                    {item.loanerStatus && loanerType === 2 &&
                        <div className="order-product-replacementfee">
                            <span style={{fontWeight: 600}}>*Loaner Fee</span><br/>
                            You will be charged up front for the loaner for the amount shown. You may also be charged up to the full replacement value should the loaner be damaged or lost.  Call your Sales Representative to proceed.
                            {this.renderLoanerActionButtons(item)}
                        </div>                            
                    }

                    <div className="order-product-actions-space action-column">{this.renderActionButtons(item)}</div>
                </div>
                <div className="item-wrapper" style={{paddingLeft: 0, paddingRight: 0}}>
                    <div className="order-product-image">
                        <ProductImage width={125} alt={imageAlt} url={imagePath} className="img" onClick={() => history.push(detailUrl)} />
                        {facility.showVendorCertifications && actionableLines.length > 0 && item.certifications && Object.keys(item.certifications).length ? <div className="certifications-wrapper">{renderCerts(item.certifications)}</div> : null}
                    </div>
                    <div className="order-product-details">
                        {isContractProApproval && <div className="contract-badge"><span>CONTRACT</span></div>}
                        <div className="order-product-description p-description" onClick={() => history.push(detailUrl)}>{title}</div>
                        <div className="order-item-attribute"><span>Item #:</span><span>{`${item.displayPartNumber || item.partNumber}`}</span></div>
                        <div className="order-item-attribute"><span>Condition:</span><span>{`${item.condition ? item.condition : ''} ${item.orderType !== 'REPAIR' ? item.purchaseChoice : ''}`}</span></div>
                        <div className="order-item-attribute"><span>Warranty:</span><span>{item.warranty}</span></div>
                        <div className="order-item-attribute"><span>Return Status:</span><span>{item.nonReturnable ? 'Not Returnable' : 'Returnable'}</span></div>
                        <div className="order-item-attribute est-mobile hidden"><span>Est. Ship date: </span><span>{renderShipping()}</span></div>
                        <div className="order-item-attribute ref-mobile"><span>Reference: </span><span>#{item.lineItemId}</span></div>
                        <div className="required-information-desktop">
                            <div className="required-information">Asset Information <span className="edit-asset-info" onClick={() => this.setState({showInfoCenterDialog: true, infoCenterTab: 'Asset', selectedLineItem: item})}>Edit</span></div>
                            { item.fieldValues
                                .filter(requiredField => requiredField.value)
                                .filter(requiredField => requiredField.lineItemId === item.lineItemId)
                                .map(requiredField => 
                                    (<div key={requiredField.prompt} className="item-attribute"><span>{requiredField.prompt}:</span> <span>{requiredField.value}</span>
                                    </div>)
                                )
                            }
                            {item.formularyFields.filter(f => f.value).map(f => <div key={f.prompt} className="item-attribute"><span>{f.prompt}:</span> <span>{f.value}</span></div>)}
                        </div>
                        {(regex.test(item.shippingAddress) && !item.isServiceItem) && <div className="shipping-information">
                            <div className="shipping-information-header">
                                <div>Shipping Information </div>
                                {actionableLines.length > 0 && !item.isContractProOption ? <div className="side-actions">
                                    {isEditingThisOrderLine && isLoadingShippingOptions ? <React.Fragment>
                                        <div className="loading-options-header">Loading Shipping Options...</div>
                                        <div className="loader" />
                                    </React.Fragment> : <React.Fragment>
                                        {isEditingThisOrderLine ?
                                            <span className="edit-asset-info" onClick={() => this.cancelEditShippingInfo(item.lineItemId)}>Cancel</span> :
                                            <span className="edit-asset-info" onClick={() => this.editShippingInfo(item.lineItemId)}>Edit</span>
                                        }
                                    </React.Fragment>}
                                </div> : null}
                            </div>
                            {isEditingThisOrderLine && <ShippingMethodEdit
                                lineItemIds={actionableLines.map(x => x.lineItemId)}
                                onLoading={() => this.onShipMethodLoading(item.lineItemId)}
                                onFinishLoading={() => this.onShipMethodFinishLoading(item.lineItemId)}
                                onSave={() => this.onShipMethodEditSave()} />}
                            {(!isEditingThisOrderLine && !isLoadingShippingOptions) && <React.Fragment>
                                <span className="item-attribute"><span>Shipping Address:</span><span>{item.shippingAddress}</span></span>
                                <span className="item-attribute"><span>Shipping ATTN:</span><span>{item.shippingAttention}</span></span>
                                {!item.isContractProOption && <React.Fragment>
                                    <span className="item-attribute"><span>Carrier:</span><span>{item.carrier}</span></span>
                                    <span className="item-attribute"><span>Shipping Method:</span><span>{item.shippingPriority}</span></span>
                                    <span className="item-attribute"><span>Shipping Insurance:</span><span>{item.shippingInsurance ? 'Yes' : 'No'}</span></span>
                                </React.Fragment>}
                            </React.Fragment>}
                        </div>}
                        <div className="uploads" style={{display: 'flex'}}>
                            <div className="attachments" onClick={() => this.setState({showInfoCenterDialog: true, infoCenterTab: 'Attachments', selectedLineItem: item})}>
                                <span>
                                    <i className="fa fa-copy" /> Attachments <span> <span>({item.attachments.length}) </span> </span>
                                </span>
                            </div>
                        </div>
                        {item.hasCreditMemo && 
                            <div className="uploads" style={{display: 'flex'}}>
                                <div className="attachments" onClick={() => downloadCreditMemos(item.lineItemId) }>
                                    <span>
                                        <i className="fa fa-file-o" />Print credit memo(s)<span></span>
                                    </span>
                                </div>
                            </div>
                        }
                        {settings.proofOfDeliveryLinks && item.hasPODLetter && <div className="pod-link" onClick={() => this.onShowPodLetter(item.lineItemId)}><i className="fa fa-file-text-o" style={{paddingRight: '10px'}} />Proof of Delivery</div>}
                        <div className="note-container">
                            <span onClick={() => this.setState({showInfoCenterDialog: true, infoCenterTab: 'AllNotes', selectedLineItem: item})}>
                                <i className="fa fa-calendar" />Notes<span> <span>({item.notes.length}) </span> </span>
                            </span>
                            {item.notes && item.notes.length > 0 && <div>
                                <div className="note-item-top">{new Date(item.notes[0].dateCreated).toLocaleDateString('en-US')}</div> 
                                <div className="note-item">{item.notes[0].text} </div>
                            </div>} 
                        </div>
                        <Xs>
                            {renderMobileFooter()}
                        </Xs>
                        <Sm>
                            {renderMobileFooter()}
                        </Sm>
                    </div>
                    <Xs>
                        {renderMobileActionButtons()}
                    </Xs>
                    <Sm>
                        {renderMobileActionButtons()}
                    </Sm>
                    {renderBottomSection(item)}

                    {this.renderLoanerRequestedOrQuoted(item)}
                </div>
                { showWarningMessage && <div className="card-warrning-info">
                    <div className="esd-warning-item"> <img style={{width: '20px', marginBottom: '5px', marginRight: '5px'}} src="/images/icn_orange-exclamation@2x.png" />{warrningMessage}</div>
                    { showFollowupDate && (<div className="followup-warning-item">Follow up date: {moment(item.followupDate).format('MM/DD/YYYY')} </div>) } 
                </div>}                
                {item.altOptions && item.altOptions.map(option => this.renderAltSourceOptions(item, option, true))}
            </div>
        )        
    }

    renderAltSourceOptions = (item, option, hasActionButtons) => {

        const {imagePath, imageAlt, title} = item;
        const {actionableLines, updatingQuantity} = this.state;
        const {user: {facilities, settings}, history} = this.props;

        const allowQtyEdit = actionableLines.length > 0;
        const hidePricing = settings.hidePricing;
        
        let {detailUrl} = item;

        const selectedOption = item.options.find(o => o.isSelected);
        const unitPrice = item.unitPrice || selectedOption.price;

        if (item.isVariantProduct) {
            if (selectedOption) detailUrl = `${detailUrl}?pspn=${selectedOption.psPartNumber}&vrnt=${selectedOption.itemNumber}`;
        }
        
        const renderQuantity = () =>
            (<QuantitySelector key={`mobile-cart-qty-${item.lineItemId}`} className="quantity-select"
                style={{marginTop: -10, background: 'white', padding: 0, paddingLeft: 15}}
                selectedValue={item.quantity}
                ref={(input) => {
                    this.qtyInput = input 
                }}
                onChange={(e) => this.handleQuantityChange(e.target.value, item)}
                disabled={updatingQuantity || item.orderType === 'REPAIR' ? 'disabled' : undefined}
            />)        

        const renderMobileFooter = () => (<>

            <div className="required-information-mobile" onClick={() => this.setState({showInfoCenterDialog: true, infoCenterTab: 'Asset', selectedLineItem: item})}>Required &amp; Optional Information</div>
            <div className="mobile-quantity-price mobile-quantity-line">
                {allowQtyEdit ? renderQuantity() : <span className="price-value" style={{fontSize: 16}}>Qty: <strong>{item.quantity}</strong></span>}
                <span className="price" style={{fontSize: 16, marginRight: 20}}><span className="price-value" style={{fontSize: 16}}>{!hidePricing && formatMoney(unitPrice)}</span><span>/{item.uomCode ? item.uomCode.toLowerCase() : ''}</span></span>
                <span className="price"><span className="price-value" style={{fontSize: 27, marginLeft: 0}}>{!hidePricing && formatMoney(unitPrice * item.quantity)}</span></span>
            </div>

        </>);

        const renderMobileActionButtons = () => <div className="order-product-actions-space-mobile hidden action-column">{this.renderAltSourceActionButtons(option, item)}</div>;
                
        let esd = 'Pending';

        if (option && option.estimatedShipDate)
            esd = moment(option.estimatedShipDate).format('MM/DD');

        return (
            <div>
                <div className="order-detail-header" style={{color: '#2C3A47'}}>
                    <span className={`order-facility-header alt ${!hasActionButtons && 'has-no-action-button'}`} style={{color: '#2C3A47'}}>
                        <div style={{minWidth: 250, display: 'flex', width: '100%'}}><span className="facility-name">Alternate Option(s)</span>  <span className="ref-desktop hidden">(Ref <span className="facility-subtotal">#{item.lineItemId}<span style={{fontWeight: 400}}>)</span></span></span></div>
                    </span>                    
                    <div className="order-product-lead" style={{width: '145px'}}>
                        <div className="desc">This option ships faster</div>
                        <div style={{fontWeight: '600'}}>Estimated to ship {esd}</div>
                    </div>
                    <div className="order-product-quantity" style={{alignItems: 'baseline'}}>
                        {allowQtyEdit ? renderQuantity() : <span className="order-product-price">{option.quantity}</span>}
                    </div>
                    <div className="order-product-price"><span>{!hidePricing && formatMoney(option.price)}</span></div>
                    <div className="order-product-extPrice">{!hidePricing && formatMoney(option.price * option.quantity)}</div>
                    <div className="order-product-actions-space action-column">{this.renderAltSourceActionButtons(option, item)}</div>
                </div>
                <div className="item-wrapper" style={{paddingLeft: 0, paddingRight: 0}}>
                    <div className="order-product-image" onClick={() => history.push(detailUrl)}>
                        <ProductImage width={125} alt={imageAlt} url={imagePath} />
                    </div>
                    <div className="order-product-details">                        
                        <div className="order-product-description p-description" onClick={() => history.push(detailUrl)}>{option.description}</div>
                        <div className="order-item-attribute"><span>Item #:</span><span>{option.partNumber}</span></div>
                        <div className="order-item-attribute">
                            <div className="alt-condition-wrapper">
                                <span>Condition:</span><span>{`${option.condition ? option.condition : ''}`}</span>
                            </div>
                        </div>
                        <div className="order-item-attribute"><span>Warranty:</span><span>{option.warranty}</span></div>
                        <div className="order-item-attribute"><span>Return Status:</span><span>{option.returnStatus}</span></div>
                        <div className="order-item-attribute est-mobile hidden"><span>Est. Ship date: </span><span>{esd}</span></div>
                        <div className="order-item-attribute ref-mobile"><span>Reference: </span><span>#{item.lineItemId}</span></div>                                                                        
                        <div className="note-container">
                            <span onClick={() => {
                                this.setState({showInfoCenterDialog: true, infoCenterTab: 'AllNotes', selectedLineItem: item, conditionNotes: option.lineItemConditionalNotes, showOnlyConditionNotes: true})
                            }}>
                                <i className="fa fa-calendar" />Notes<span> <span>({option.lineItemConditionalNotes.length}) </span> </span>
                            </span>
                            {option.lineItemConditionalNotes && option.lineItemConditionalNotes.length > 0 && <div>
                                <div className="note-item-top">{new Date(option.lineItemConditionalNotes[0].dateCreated).toLocaleDateString('en-US')}</div> 
                                <div className="note-item">{option.lineItemConditionalNotes[0].text} </div>
                            </div>} 
                        </div>
                        <Xs>
                            {renderMobileFooter()}
                        </Xs>
                        <Sm>
                            {renderMobileFooter()}
                        </Sm>
                    </div>
                    <Xs>
                        {renderMobileActionButtons()}
                    </Xs>
                    <Sm>
                        {renderMobileActionButtons()}
                    </Sm>                    
                </div>                
            </div>
        )
    }

    renderActionButtons(item) {
        const {history, params, network: {initialPage, tokenInfo: {subscriptionId}}} = this.props;
        const {approvalGroupName, showNudgeRequester} = this.state;

        if (!item.loanerStatus && (item.statusId === 8 || item.statusId === 25)) {
            return (
                <div className="action-buttons">
                    {item.dateReceived && <div className="action-item">
                        <div className="check-in-label"><i className="fa fa-check" />Checked In</div>
                        <div className="check-in-text">{`Checked-in by ${item.checkedInBy} on ${item.dateReceived}`}</div>
                    </div>}

                    {!item.preventCheckIn && (item.statusId === 8 || (item.orderTypeId === 1 && item.statusId === 25)) && !item.dateReceived && <div className="action-item"> <Button style={{height: 35}} secondary={true} onClick={() => this.setState({showCheckInDialog: true, selectedLineItem: item})} >CHECK IN</Button></div>}
                    {item.statusId === 8 && <div className="action-item" onClick={() => this.setState({showReturnDialog: true, selectedLineItem: item})}>RETURN</div>}

                    <div className="action-item" onClick={() => this.setState({ showTrackingPanel: item.lineItemId })}>TRACK PACKAGE</div>
                    <div className="action-item" onClick={() => history.push(item.detailUrl)}>BUY IT AGAIN</div>

                    {(params.wo_id && params.wo && subscriptionId && initialPage === '/orders/history' && !item.customerOrderKey) && <div className="action-item" onClick={() => this.associateTMSOrder(item.lineItemId)}>EXPORT ORDER</div>}
                    {(showNudgeRequester) && <div className="action-item"><Button className="button-nudge" secondary={true} onClick={() => this.onNudgeRequester(item)}>Nudge Requester</Button></div>}
                </div>
            )
        }
        if (item.extStatusId === 28) {
            const {user: {facilities}} = this.props;
            const facility = _.find(facilities, f => _.toNumber(f.facilityId) === _.toNumber(item.facilityId)) || {};
            const approvalLimit = facility.purchaseLimit;
            const needsApproval = item.extendedPrice > approvalLimit;
            const selectedOption = item.options.find(o => o.isSelected);
            let disableApproval = selectedOption && selectedOption.moq > item.quantity;

            return (
                <div className="action-buttons">
                    {item.hasApproved ?
                        <>
                            <div className="action-item no-click normal" style={{fontWeight: 400}}>Submitted for Approval</div>{(approvalGroupName) && <div className="action-item"><Button className="button-nudge" secondary={true} onClick={() => this.onNudgeApprover(item, false)}>Nudge Approver</Button></div>}</> :
                        <React.Fragment>
                            {item.canApprove ?
                                <>
                                    {needsApproval ? 
                                        <div 
                                            className={`action-item ${disableApproval ? 'is-disabled' : ''}`}
                                            disabled={disableApproval} 
                                            onClick={() => !disableApproval ? this.onApprove(item) : ''}>
                                            Approve
                                        </div> :
                                        <div className="action-item">
                                            <ApprovalPurchaseButton lineItemIds={[item.lineItemId]} showLink={true} isDisabled={disableApproval} />
                                        </div>}
                                    <div className="action-item" onClick={() => this.setState({showRejectDialog: true, rejectItems: [item]})}>Reject</div>
                                    {item.options.length > 1 && !item.isVariantProduct &&
                                        <div className="action-item"
                                            onClick={() =>
                                                this.setState({
                                                    showBuyingOptions: true,
                                                    buyingOptionsImage: item.imagePath,
                                                    buyingOptions: item.options,
                                                })}> Other Buying Options ({item.options.length - 1})
                                        </div>
                                    }
                                </> :
                                <>
                                    <div className="action-item no-click normal" style={{fontWeight: 400}}>Submitted for Approval</div>
                                    {(approvalGroupName) && <div className="action-item"><Button className="button-nudge" secondary={true} onClick={() => this.onNudgeApprover(item, false)}>Nudge Approver</Button></div>}
                                </>}
                        </React.Fragment>}
                </div>
            );
        }
        if (item.extStatusId === 29) {
            return (
                <div className="action-row">
                    <div className="rejected"><i className="fa fa-remove" />Rejected</div>
                </div>);
        }        

        return null;
    }

    renderLoanerActionButtons(item) {
        if (item.loanerStatus !== 'Requested' || item.loanerStatus !== 'Quoted') {
            return (
                <div>
                    <div className="loaner-action-buttons" style={{paddingTop: 16}} onClick={() => this.setState({ showTrackingPanel: item.lineItemId })}>TRACK PACKAGE</div>                    
                </div>
            )
        }
    }

    renderAltSourceActionButtons(option, item) {
        const {altAcceptBusy, altRejectBusy, altBusyVendorResearchId} = this.state;
        return (
            <div className="action-buttons">
                {!option.isSelected ? 
                    <React.Fragment>
                        <Button secondary={true} onClick={() => this.onCheckAltPoNumber(option, item)} className="alt-button" loading={altAcceptBusy && option.vendorResearchId === altBusyVendorResearchId } disabled={altRejectBusy}>REQUEST</Button>
                        <Button secondary={true} onClick={() => this.onRejectAlternateOption(option)} className="alt-button alt" loading={altRejectBusy && option.vendorResearchId === altBusyVendorResearchId} disabled={altAcceptBusy}>REJECT</Button>  
                    </React.Fragment> :
                    <React.Fragment>
                        <div className="alt-requested">
                            <img src={`/images/icn_checkmark.png`} />
                            <span>Requested</span>
                        </div>
                        <div className="alt-requested-msg">Receipt of the alternate option is subject to the cancellation of the originally ordered product.</div>
                    </React.Fragment>
                }
            </div>
        )        
    }

    editShippingInfo = (lineItemId) => {
        this.setState(prevState => ({
            currentlyEditingOrderLineId: lineItemId,
            loadingShippingOptionsByLineId: {...prevState.loadingShippingOptionsByLineId, [lineItemId]: true},
        }));
    };

    cancelEditShippingInfo = (lineItemId) => {
        this.setState(prevState => ({
            currentlyEditingOrderLineId: null,
            loadingShippingOptionsByLineId: {...prevState.loadingShippingOptionsByLineId, [lineItemId]: false},
        }));
    }

    onShipMethodLoading = (lineItemId) => {
        this.setState(prevState => ({
            loadingShippingOptionsByLineId: {...prevState.loadingShippingOptionsByLineId, [lineItemId]: true},
        }));
    }

    onShipMethodFinishLoading = (lineItemId) => {
        this.setState(prevState => ({
            loadingShippingOptionsByLineId: {...prevState.loadingShippingOptionsByLineId, [lineItemId]: false},
        }));
    }

    onShipMethodEditSave = () => {
        this.setState({currentlyEditingOrderLineId: null});
        this.getData();
    }

    onShowRTP = () => {
        const {match: {params: {lineItemId}}} = this.props;
        const {orderLines} = this.state;
        const WO_KEY = '22222222-2222-2222-2222-222222222222';
        let selectedLine = orderLines.filter(x => x.lineItemId == lineItemId);
        const field = selectedLine[0].fieldValues;
        const workOrder = field.filter(x => x.fieldUid === WO_KEY);
        const wo = workOrder[0].value;

        const allLinesWithSameWorkOrder = _.filter(orderLines, line => _.find(line.fieldValues, {fieldUid: WO_KEY, value: wo})).map(line => line.lineItemId);

        const fileName = lineItemId + '&WO=' + wo;
        const pdfUrl = `/CustomerCommunicationsService/api/v1/report/RTP?lineItems=${allLinesWithSameWorkOrder.join('&lineItems=')}`;
        axios.get(pdfUrl, {responseType: 'arraybuffer', headers: {'Content-Type': 'application/json', 'Accept': 'application/pdf'}}).then(x => {
            const data = x.data;
            const file = new Blob([data], {type: 'application/pdf'});

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(file);
                return;
            }

            const fileURL = URL.createObjectURL(file);
            let link = document.createElement('a');
            link.type = 'hidden';
            link.href = fileURL;
            link.download = `${fileName}.pdf`;
            document.body.appendChild(link);
            link.click();

            setTimeout(function () {
                document.body.removeChild(link);
                window.URL.revokeObjectURL(fileURL);
            }, 100);

        });

    };

    OnShowTrinity = (orderId) => {
        const {match: {params: {lineItemId}}} = this.props;
        const {orderLines} = this.state;
        const fileName = `THCE_${orderId}.csv`;
        const csv = `/CustomerCommunicationsService/api/v1/report/TR?orderId=${orderId}`;
        axios.get(csv, {responseType: 'arraybuffer', headers: {'Content-Type': 'application/json', 'Accept': 'text/csv'}}).then(x => {
            const data = x.data;
            if (x.status === 200) {
                const file = new Blob([data], {type: 'text/csv'});

                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(file);
                    return;
                }

                const fileURL = URL.createObjectURL(file);
                let link = document.createElement('a');
                link.type = 'hidden';
                link.href = fileURL;
                link.download = `${fileName}`;
                document.body.appendChild(link);
                link.click();

                setTimeout(function () {
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(fileURL);
                }, 100);
            }
        });

    };
    onPackingBundle = (lineItemId) => {
        const {history} = this.props;
        history.push(
            {
                pathname: '/orders/packingSlip',
                state: {
                    lineItemId: lineItemId,
                },
            }
        );    
    };

    onShowConfirmation = () => {
        let {orderLines} = this.state;
        const {resetCheckout, saveCheckout} = this.props;
        let o = {};
        if (orderLines.length) {
            o = orderLines[0]
        }
        
        resetCheckout();
        let poLines = [];
        let shipments = [];
        // clone array
        let checkoutLines = JSON.parse(JSON.stringify(orderLines));
        checkoutLines.forEach(x => {
            x.fields = x.fieldValues;
            x.uomCode = x.unitOfMeasure;
            x.price = x.unitPrice;
            x.quantity = x.quantity;
            x.oemName = x.manufacturer;
            x.requestorName = x.requestor;
            x.imagePath = x.imagePath;

            x.poLines.filter(p => p.processingFee > 0).forEach(p => p.freight = 0);

            poLines = poLines.concat(x.poLines);

            shipments.push({
                
                lineItemIds: [x.lineItemId],
                shippingMethod: {
                    carrierId: x.shippingMethod && x.shippingMethod.toLowerCase().indexOf('fedex') > -1 ? 1 : 2,
                    shippingInsurance: x.shippingInsurance,
                },
                selectedShipOption: {
                    serviceTypeDescription: x.shippingPriority,
                    netShippingCharge: _.sumBy(poLines, 'shipping'),
                },
                
            });
        });

        const shippingAddress = {addressDisplay: o.shippingAddress};        
                    
        let checkout = {
            items: checkoutLines,
            facility: {facilityName: o.facilityName},
            facilitySettings: {},            
            shipments: shipments,
            paymentInformation: {
                poNumber: o.poNumber,
                paymentMethod: {
                    value: o.paymentMethod,
                },
            },            
            shippingLocation: {
                attentionTo: o.shippingAttention,
                includePo: o.includePo,
            },
            billingAddress: o.billingAddress,
            shippingAddress: shippingAddress,
            totals: {
                taxes: _.sumBy(poLines, 'tax'),
                creditCardFees: _.sumBy(poLines, 'creditCardFee'),
                processingFees: _.sumBy(poLines, 'processingFee'),
                minOrderFees: _.sumBy(poLines, 'minOrderFee'),
                exchangeFees: _.sumBy(poLines, 'exchangeFee'),
                programFees: _.sumBy(poLines, 'programFee'),
                oemFees: _.sumBy(poLines, 'oemFee'),
                transitCoverages: _.sumBy(poLines, 'transitCoverage'),
                shipping: _.sumBy(poLines, 'freight'),
            },
        };

        saveCheckout(checkout);
        this.setState({showConfirmation: true});
    }

    onCreateLabels () {
        let {orderLines} = this.state;
        let lineItemIds = orderLines.filter(x => x.canGenerateShippingLabel).map(x => x.lineItemId);
        this.setState({creatingLabels: true});

        axios.post('/ShoppingService/api/v1/orders/rga/createshippinglabels', lineItemIds).then(() => {
            this.getData();
        });
    }

    onShowTrackOrderSMSDialog (e) {
        this.setState({showTrackOrderSMSDialog: true});

        const {orderLines} = this.state;
        let currOrder = {};

        if (orderLines.length) {
            currOrder = orderLines[0];
        }
    }

    handleQuantityChange = debounce((value, ci) => {
        let {orderLines} = this.state;
        let ol = orderLines.find(o => o.lineItemId == ci.lineItemId);
        ol.quantity = value;

        if (parseInt(value)) {
            this.setState({updatingQuantity: true});
            axios.post('/ShoppingService/api/v1/approval/update/qty',
                {
                    lineItemId: ol.lineItemId,
                    facilityId: ol.facilityId,
                    hasBulkPricing: ol.hasBulkPricing,
                    qty: ol.quantity,
                }).then(() => {
                this.getData();
            })
        }
    }, 500)

    logOneSourceClick = () => {
        logEvent('oneSource Click', {
            'Source': 'Order Details',
            'Source Descriptor': window.location.href,
        });
    };

    checkSMSTextToShow = (orderLines) => {
        let o = {};

        if (orderLines.length) {
            o = orderLines[0]
        }

        if (o.haveSetOLN)
            return false;

        const {user: {settings, info}} = this.props;
        const {mobileNumber, isMobileVerified, isOptedOut, notifications} = settings.notificationSettings;

        const {
            hideTrackOrderSMSPrompt,
        } = this.state;

        const isApproval = orderLines.some(x => x.isApproval);
        const isAnyNotDelivered = orderLines.some(x => x.isDelivered !== true);
        const hasServiceItems = orderLines.some(x => x.isServiceItem);

        let showSmsPrompt = false;

        if (!hideTrackOrderSMSPrompt && !isApproval && isAnyNotDelivered && !hasServiceItems && (o.requesterId === info.contactId)) {
            showSmsPrompt = !isMobileVerified || !mobileNumber || isOptedOut;
            if (!showSmsPrompt) {
                let ord = _.filter(notifications, {textActive: 1});
                if (!ord || ord && ord.length < 5) {
                    showSmsPrompt = true;
                } else {
                    // we check whether the 5 we expect are those 5 below
                    // 'Shipped' / 'Backorder / Est. Ship Date' / 'Delivered' / 'Order Changed' / 'Arriving Today'
                    let arrOrd = _.filter(notifications, (i) => i.notificationType === 6 || i.notificationType === 7 ||
                            i.notificationType === 15 || i.notificationType === 16 || i.notificationType === 27);

                    // 'Order Rejected' / 'Order Approved'
                    let aprOrd = _.filter(notifications, (i) => i.notificationType === 4 || i.notificationType === 5);
                    arrOrd = _.union(arrOrd, aprOrd);

                    if (orderLines.some(x => x.orderTypeId === 4)) {
                        // 'Repairs'
                        let arrRepair = _.find(notifications, {notificationType: 26});
                        arrOrd = _.union(arrOrd, arrRepair);
                    }

                    if (orderLines.some(x => x.isExchangeFormularyOption)) {
                        // 'Exchanges'
                        let arrExch = _.find(notifications, {notificationType: 11});
                        arrOrd = _.union(arrOrd, arrExch);
                    }

                    showSmsPrompt = !arrOrd.every(n => ord.includes(n));
                }
            }
        }

        return showSmsPrompt;
    }

    loadCompanySettings = (facilityId) => {
        let {loadFacilityCheckoutSettings} = this.props;
        
        Promise.all([loadFacilityCheckoutSettings(facilityId)])
    }

    onCheckAltPoNumber = (option, item) => {
        if (item.poNumber) {
            const altOption = _.merge(option, {altPoNumber: item.poNumber});
            this.setState({showAltPoModal: true, altPoOption: option});
        } else
            this.onAcceptAlternateOption('', option);
    }

    onAcceptAlternateOption = (poNumber, option) => {
        const {altPoNumber, altPoOption} = this.state;

        this.setState({altAcceptBusy: true, altBusyVendorResearchId: option.vendorResearchId});

        let request = _.merge(option || altPoOption, {altPoNumber: poNumber});

        axios.post('/ShoppingService/api/v1.0/orders/alt/accept', request).then(() => {
            this.getData();
        });
    }

    onRejectAlternateOption = (option) => {
        const {altPoNumber} = this.state;
        this.setState({altRejectBusy: true, altBusyVendorResearchId: option.vendorResearchId});

        axios.post('/ShoppingService/api/v1.0/orders/alt/reject', option).then(() => {
            this.getData();
        });
    }

    acceptLoanerDialog = (item) => {
        this.setState({showLoanerAcceptanceDialog: true, loanerItem: item});
    }

    loanerAccepted = () => {
        const {loanerItem} = this.state;        

        axios.post(`/ShoppingService/api/v1/orders/loaner/accept/${loanerItem.lineItemId}`).then(() => {
            this.setState({ showLoanerAcceptanceDialog: false, loanerItem: null });            
        });
    }

    rejectLoaner = (item) => {       
        const { history } = this.props;
        axios.post(`/ShoppingService/api/v1/orders/loaner/reject/${item.lineItemId}`).then(() => {
            this.setState({showLoanerAcceptanceDialog: false});            
            history.goBack();
        });
    }

    showConfirmReturnModal = ( value ) => {
        this.setState({ showReturnConfirmation: value })
    }

    renderReturnConfirmMessage = () => {
        return (<Popup
            confirmText="OK"
            show={this.state.showReturnConfirmation}
            onConfirm= {() => this.setState({ showReturnConfirmation: false })}
            onCancel = {() => this.setState({ showReturnConfirmation: false })}
            onClose = {() => this.setState({ showReturnConfirmation: false })}
            className="asset-information-dialog">
            <div className="asset-information-dialog">
                <span>{RETURN_CONFIRMATION_MSG}</span>            
            </div>
        </Popup>)
    }
    
    render() {
        const {
            orderLines,
            actionableLines,
            showBuyingOptions,
            buyingOptions,
            buyingOptionsImage,
            showRejectDialog,
            rejectItems,
            saving,
            showTrackingPanel: trackingPanelLineId,
            showCheckInDialog,
            showReturnDialog,
            showMobileShippingModal,
            selectedLineItem,
            showTrackingNumberDialog,
            showInfoCenterDialog,
            conditionNotes,
            infoCenterTab,
            showTrackOrderSMSDialog,
            hideTrackOrderSMSPrompt,
            updatingQuantity,
            showNudgeConfirmDialog,
            showNudgeRequesterConfirmDialog,
            approvalGroupName,
            showConfirmation,
            creatingLabels,
            showSmsPrompt,
            autoScrollLineItemId,
            showAltPoModal,
            altPoOption,
            showMissingInvoice,
            showOnlyConditionNotes,
            showLoanerAcceptanceDialog,
            loanerStatusAssociated,
        } = this.state;

        let o = {};

        if (orderLines.length) {
            o = orderLines[0]
        }

        const {user: {facilities, settings, info}, companies: {oneSourceMappings}, history, sessionStorageAvailable, bloomreach: {isPreview}, location} = this.props;
        
        const fac = facilities.find(x => x.facilityId == o.facilityId) || {};
        const approvalLimit = fac.purchaseLimit;
        const submitForPo = fac.submitForPo
        const subTotal = _.sumBy(actionableLines, 'extendedPrice');
        const prevPageLabel = (sessionStorageAvailable && sessionStorage.prevGrid) || '';
        const editableStatuses = [1, 3, 4, 6, 9, 10, 13, 14, 20, 26, 27, 28, 30];
        const hasNonContractOptions = orderLines.some(x => !x.isContractProOption);

        const hasActionButtons = orderLines.some(ol => ol.statusId === 8 || ol.statusId === 25 || ol.extStatusId === 28);

        const oneSourceMappingsForOrder = oneSourceMappings.filter(m => {
            return m.partsSourceRefs.length > 0
                && orderLines.some(orderLine => _.some(m.partsSourceRefs, ['id', _.get(orderLine, 'oemId', '').toString()]));
        });
        const oneSourceLinkParameter = oneSourceMappingsForOrder.length === 1
            ? `?oemId=${oneSourceMappingsForOrder[0].partsSourceRefs[0].id}`
            : '';

        const isODS = orderLines.some(o => o.orderType === 'On Demand Service');
        const showMobileShipping = o.statusId === 7 || o.statusId === 8 || o.statusId === 25;
        const disableApproval = orderLines.some(o => o.options?.some(op => op.moq > op.quantity));
        const renderSmsButton = () => (<div className="order-detail-sms" onClick={e => this.onShowTrackOrderSMSDialog(e)}>
            <div className="order-detail-sms-phone-icon-wrapper">
                <img src={'/images/icn_sms-notification-bubble.png'} alt="Track Order" style={{width: 34, height: 42}} />
            </div>
            <div className="order-detail-sms-wrapper">
                <div className="order-detail-sms-text-wrapper">
                Get Text Updates About Your Order
                </div>
                <div className="order-detail-sms-orange-button"><Button style={{height: 34}} secondary={true} onClick={e => this.onShowTrackOrderSMSDialog(e)} >TEXT ME</Button></div>
            </div>
        </div>);

        return (<div className="order-detail">
            <PageMetaData
                title="Order Detail"
                pageType="other"
                trackAnalytics={true} />
            {(isODS || isPreview) && <BrComponent path="main/container" />}
            {updatingQuantity ?
                <div className="updating-quantity">
                    <div className="loader" />
                </div> : null
            }
            <div>
                <span className="order-label chevron hidden-xs">My Account</span>
                {prevPageLabel ?
                    <span className="order-sub-label">{prevPageLabel}</span> : null}
            </div>
            {prevPageLabel ? <div className="back-link"><span onClick={() => history.goBack()}>Back to {prevPageLabel}</span></div> : null}

            <div className="order-body">
                <div className="order-number-row order-text-mobile hidden">
                    {o.orderId ? <span className="order-number">{`Order #: ${o.orderId}`}</span> : null}
                </div>

                <div className="order-number-row ">
                    {o.orderId ? <span className="order-number order-text" style={{'marginTop': `${!prevPageLabel ? 20 : 0}`}}>{`Order #: ${o.orderId}`}</span> : null}

                    {actionableLines.length > 0 &&
                        <div className="summary-totals">
                            <span className="info">{`${actionableLines.length}`} {actionableLines.length === 1 ? 'item' : 'items'} ({_.sumBy(actionableLines, 'quantity')} total) selected totaling to <span className="money-label">{formatMoney(subTotal)}</span></span>
                            <div>
                                {approvalLimit >= subTotal ? <ApprovalPurchaseButton lineItemIds={actionableLines.map(x => x.lineItemId)} buttonText="Purchase All" isDisabled={disableApproval} />
                                    : <Button
                                        secondary={true}
                                        disabled={disableApproval}
                                        onClick={() => !disableApproval ? this.onApprove(null, true) : ''}
                                        loading={saving}>
                                        APPROVE ALL
                                    </Button>}

                                <Button secondary={true} className="reject" onClick={() => this.setState({rejectItems: actionableLines, showRejectDialog: true})}>REJECT ALL</Button>
                            </div>
                        </div>}
                </div>

                <div className="order-info">
                    <div className="left-column">
                        {o.dateCreated ? <div className="detail">Date Created:<span>{new Date(o.dateCreated).toLocaleDateString('en-US')}</span></div> : null}
                        <div className="detail">Facility:<span>{o.facilityName}</span></div>
                        <div className="detail">Requester:<span>{o.requestor}</span></div>
                        {o.isServiceItem &&
                            <React.Fragment>
                                <div className="detail">Service Type:<span>{o.serviceInfo.serviceType}</span></div>
                                <div className="detail">Service Description:<span>{o.serviceInfo.serviceNeeded}</span></div>
                                <div className="detail">Time Frame Needed by:<span>{o.serviceInfo.turnaroundTime}</span></div>
                                <div className="detail">Model Number:<span>{o.serviceInfo.model}</span></div>
                                <div className="detail">Engineering Credentials:<span>{o.serviceInfo.serviceTechnicianCredentials}</span></div>
                                <div className="detail">Location Details:<span>{o.serviceInfo.locationDetail}</span></div>
                            </React.Fragment>}
                    </div>

                    {showSmsPrompt ?
                        <div className="right-column">
                            <div className="order-detail-info order-detail-sms-button">
                                <Md>
                                    {renderSmsButton()}
                                </Md>
                                <Lg>
                                    {renderSmsButton()}
                                </Lg>
                                <Sm>
                                    {renderSmsButton()}
                                </Sm>
                                <Xs>
                                    {renderSmsButton()}
                                </Xs>
                            </div>
                        </div>
                        : null}
                    {settings.oneSource && oneSourceMappingsForOrder.length > 0 && <div className="right-column onesource-container">
                        <img alt="One Source" src="/images/logo_one-source.svg" />
                        <Link
                            to={`/onesource-document-search${oneSourceLinkParameter}`}
                            target="_blank"
                            onClick={this.logOneSourceClick}
                        >
                            Access your oneSOURCE Library
                        </Link>
                    </div>}
                </div>

                {showMobileShippingModal && <Popup
                    onCancel ={() => this.setState({showMobileShippingModal: false})}
                    hideButtons={true}
                    show={true}>

                    <div className="order-detail">
                        <div className="order-info">
                            {renderMiddleColumn()}
                        </div>
                    </div>
                </Popup>}
            </div>
            {(!o.statusId || (o.statusId !== 26)) && <div className="print-confirmation" onClick={this.onShowConfirmation}>Print Confirmation</div>}
            {(settings.sendPennRTP && o.statusId !== 26) && <div className="print-confirmation" onClick={this.onShowRTP}> Print RTP Form </div >}
            {(settings.trinityRequisition && submitForPo && o.statusId !== 26) && <div className="print-confirmation" onClick={() => this.OnShowTrinity(o.orderId)}> Download Requisition CSV </div >}
            <div className="order-line-item">
                <div className="order-summary">
                    <div className="order-details" style={{fontSize: 16}}>
                        <div className="facility-group-wrapper show-lead-time">
                            <div>
                                <div className="order-detail-header" style={{background: 'white', height: 51, borderTop: 0}}>
                                    <span className={`order-facility-header ${!hasActionButtons && !o.orderType == "LOANER" && 'has-no-action-button'}`} style={{minWidth: 200}}>
                                        Cart Summary
                                    </span>
                                    <div className="order-product-lead"> Est. Ship Date</div>
                                    <div className="order-product-quantity">Quantity</div>
                                    <div className="order-product-price">Unit Price</div>
                                    <div className="order-product-extPrice">Ext. Price</div>
                                    {o.orderType == "LOANER" && <div id="order-product-replacementfee" /> }
                                    {/*<div className="order-product-extPrice">Test</div>*/}
                                    <div className="order-product-actions-space" />
                                </div>
                                {(!o.isContractProApproval && o.isFormularyApproval) && <div className="formulary-approval"><i className="fa fa-exclamation" />Non-Formulary Option Selected</div>}
                                {o.isContractProApproval && <div className="formulary-approval"><i className="fa fa-exclamation" />Non-Contract Option Selected</div>}
                                {orderLines.map(item => this.renderOrderLineItem(item, hasActionButtons, fac))}
                                {autoScrollLineItemId && this.scrollToAltSourceLine()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                showBuyingOptions && <BuyingOptionsDialog
                    options={buyingOptions}
                    imagePath={buyingOptionsImage}
                    onSave={this.getData}
                    onCancel={() => this.setState({showBuyingOptions: false})} />
            }

            {
                showRejectDialog &&
                <RejectApprovalDialog
                    items={rejectItems}
                    onReject={() => this.getData(true)}
                    onCancel={() => this.setState({showRejectDialog: false})} />
            }

            { trackingPanelLineId && <TrackingPanel show={true} status={o.status} lineItemId={trackingPanelLineId} hasPODLetter={o.hasPODLetter} onClose={() => this.setState({showTrackingPanel: null})} />}

            {
                showCheckInDialog &&
                <Popup
                    confirmText="OK"
                    cancelText="Cancel"
                    show={true}
                    onConfirm={this.handleOnCheckInConfirm}
                    onCancel={() => this.setState({showCheckInDialog: false, selectedLineItem: {}})}
                    className="asset-information-dialog">
                    <div className="asset-information-dialog">
                        <span><i className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}Please confirm you are acknowledging receipt of the part(s).</span>
                    </div>
                </Popup>
            }

            {
                showReturnDialog &&
                <div className="return-order-details">
                    <InitiateReturnModal 
                        onCancel={() => this.setState({showReturnDialog: false})} 
                        showConfirmReturnModal={(val) => this.showConfirmReturnModal(val)}
                        item={selectedLineItem} />
                </div>
            }

            {
                showTrackingNumberDialog &&
                <TrackingNumberEntryDialog
                    rga={selectedLineItem}
                    onCancel={() => this.setState({showTrackingNumberDialog: false})}
                    onSave={this.getData} />
            }

            {showLoanerAcceptanceDialog ? (
                <LoanerAcceptanceDialog lineItemId={o.lineItemId} onConfirm={() => this.loanerAccepted()} onCancel={() => this.setState({showLoanerAcceptanceDialog: false})} />
            ) : null}

            {
                showInfoCenterDialog &&
                <InfoCenterMain
                    reqFields={selectedLineItem.fieldValues.filter(x => x.isRequired).filter(requiredField => requiredField.lineItemId === selectedLineItem.lineItemId)}
                    optFields={selectedLineItem.fieldValues.filter(x => !x.isRequired).filter(requiredField => requiredField.lineItemId === selectedLineItem.lineItemId)}
                    fields={selectedLineItem.fieldValues.filter(requiredField => requiredField.lineItemId === selectedLineItem.lineItemId)}
                    onSubmit={this.getFieldValues}
                    activeTab={infoCenterTab}
                    formType={AssetFormType.Both}
                    onCancel={() => {this.setState({showInfoCenterDialog: false}); this.getData(); }}
                    showRequester={true}
                    requesterFacilityId={selectedLineItem.facilityId}
                    requesterId={selectedLineItem.requesterId}
                    lineItemId={selectedLineItem.lineItemId}
                    orderId={selectedLineItem.orderId}
                    submitDisabled={!editableStatuses.some(s => s === o.statusId)}
                    priority={selectedLineItem.priority == 1}
                    conditionNotes={conditionNotes}
                    showOnlyConditionNotes={showOnlyConditionNotes}
                />
            }

            {
                showNudgeConfirmDialog &&
                <Popup
                    show={true}
                    hideButtons={true}
                    onCancel={() => this.setState({showNudgeConfirmDialog: false, selectedLineItem: {}})}
                    className="asset-information-dialog" >
                    <div className="asset-information-dialog">
                        <h2>Nudge Approver for Order #{selectedLineItem.lineItemId}</h2>
                        <span className="blueText">Email reminder sent successfully!</span><br /><br />
                        <span>Your approval &quot;nudge&quot; for Order #{selectedLineItem.lineItemId} was sent to {approvalGroupName}</span>
                    </div>
                </Popup>
            }

            {
                showNudgeRequesterConfirmDialog &&
                <Popup
                    show={true}
                    hideButtons={true}
                    onCancel={() => this.setState({showNudgeRequesterConfirmDialog: false, selectedLineItem: {}})}
                    className="asset-information-dialog" >
                    <div className="asset-information-dialog">
                        <h2>Nudge Requester for Order #{selectedLineItem.lineItemId}</h2>
                        <span className="blueText">Email reminder sent successfully!</span><br /><br />
                    </div>
                </Popup>
            }

            { showConfirmation && <SalesQuote isConfirmation={true} onClose={() => this.setState({showConfirmation: false})} showShippingInfo={true} />}
            {showTrackOrderSMSDialog && 
                <TrackOrderSMSDialog 
                    orderId={o.orderId} 
                    onClose={() => this.setState({showTrackOrderSMSDialog: false})} 
                    onCloseConfirm={() => this.setState({showTrackOrderSMSDialog: false, hideTrackOrderSMSPrompt: true})}
                    screenOrigin={'Order Details'} />}
            {showAltPoModal && <AltSourcePoEntryModal onSubmit={() => this.getData()} onClose={() => this.setState({showAltPoModal: false})} option={altPoOption} />}
            {showMissingInvoice ? this.renderMissingInvoice() : null}
            {this.renderReturnConfirmMessage()}
        </div >)
    }
}
